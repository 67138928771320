import * as React from "react"
import { WindowLocation } from "@reach/router"
import Loading from "@modules/ui/components/Loading"
import { EmptyState, Link, Spacer, ThemeCss, Heading } from "gatsby-interface"
import { Feed, FeedItem } from "@modules/a11y/components/Feed"
import { BuildCard } from "@modules/build/card/components/BuildCard"
import { MdArrowBack } from "react-icons/md"
import {
  getPathToBuildDetails,
  getPathToSiteDetails,
} from "@modules/site/details/utils"
import {
  PageWithTabsContentSection,
  StandardSingleColumn,
} from "@modules/ui/layouts/Containers"
import { FormattedMessage } from "@modules/locales"
import { buildsByBranchView as buildsByBranchViewText } from "@modules/locales/default.js"
import { GoGitPullRequest } from "react-icons/go"
import { visuallyHiddenCss } from "@modules/a11y/stylesheets"
import { deploysView as deploysViewText } from "@modules/locales/default.js"
import { usePullRequestById } from "../hooks/useDeploys"
import { Waypoint } from "react-waypoint"
import { LastBuildInfo } from "@modules/site/buildsView/components/LastBuildInfo"
import TriggerBuild from "@modules/build/shared/components/TriggerBuild"
import { BuildStatus } from "@modules/graphql/types"
import { useLatestBuildByStatusByPullRequestIdQuery } from "@modules/build/shared/queries.generated"
import { useOrganizationBaseDetailsQuery } from "@modules/organization/queries.generated"
import SiteBranch from "@modules/site/shared/components/SiteBranch"
import BuildPublishedDate from "@modules/build/shared/components/BuildPublishedDate"
import { ExternalLink } from "@modules/ui/components/ExternalLink"
import { useSiteDetailsQuery } from "@modules/site/shared/queries.generated"

export type BuildsListPageProps = {
  organizationId: string
  siteId: string
  location?: WindowLocation
  pullRequestId: string
}

const linkCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[0],
})

const pullRequestIconCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[3],
  color: theme.colors.grey[60],
})

const pullRequestNumberCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[3],
  color: theme.colors.grey[60],
})

const triggerBuildCss: ThemeCss = theme => ({
  marginTop: theme.space[3],
  marginLeft: "auto",
  [theme.mediaQueries.tablet]: {
    marginTop: 0,
    marginLeft: 0,
  },
})

/**
 * When modifying this file, make sure you modify the BuildsListPage accordingly
 * to have the same UI for the builds by branches and builds by pull requests
 */
export function PullRequestBuildsPage({
  pullRequestId,
  siteId,
  organizationId,
}: BuildsListPageProps) {
  const [
    triggerBuildError,
    setTriggerBuildError,
  ] = React.useState<JSX.Element | null>(null)

  const {
    data: latestSuccessfulBuildData,
    error: latestSuccessfulBuildError,
    loading: loadingLatestSuccessfulBuild,
    refetch,
  } = useLatestBuildByStatusByPullRequestIdQuery({
    variables: {
      siteId,
      pullRequestId,
      status: BuildStatus.Success,
    },
  })

  const {
    builds,
    pullRequestById,
    loading,
    error,
    loadingMore,
    loadMore,
  } = usePullRequestById(pullRequestId, siteId)

  const {
    data: siteDetailsData,
    error: errorSiteDetails,
    loading: loadingSiteDetails,
  } = useSiteDetailsQuery({
    variables: { id: siteId },
    fetchPolicy: "cache-and-network",
  })

  const siteDetails = siteDetailsData?.siteDetails
  const repositoryUrl = siteDetails?.repository?.url

  const title = pullRequestById?.title || ""
  const pullRequestNumber = pullRequestById?.number
  const { data: orgData } = useOrganizationBaseDetailsQuery({
    variables: { id: organizationId },
  })

  if (loadingLatestSuccessfulBuild || loading || loadingSiteDetails) {
    return (
      <PageWithTabsContentSection>
        <StandardSingleColumn>
          <Loading message={buildsByBranchViewText.messages.loadingBuilds} />
        </StandardSingleColumn>
      </PageWithTabsContentSection>
    )
  }

  const actualError = error || latestSuccessfulBuildError || errorSiteDetails
  if (actualError) {
    return (
      <PageWithTabsContentSection>
        <StandardSingleColumn>
          <p data-testid="builds-list-page-error">
            <FormattedMessage<"error">
              message={buildsByBranchViewText.messages.errorLoadingBuilds}
              values={{ error: actualError?.message }}
            />
          </p>
        </StandardSingleColumn>
      </PageWithTabsContentSection>
    )
  }

  if (builds.length === 0) {
    return (
      <PageWithTabsContentSection>
        <StandardSingleColumn>
          <EmptyState
            variant="BORDERED"
            heading={buildsByBranchViewText.headers.noBuilds}
            text={buildsByBranchViewText.messages.noBuildsForPr}
          />
        </StandardSingleColumn>
      </PageWithTabsContentSection>
    )
  }

  const organization = orgData?.organizationDetails
  const backLink = getPathToSiteDetails(siteId, organizationId)
  const lastBuildSuccessfulBuild =
    latestSuccessfulBuildData?.latestBuildByStatusByPullRequestId

  return (
    <main>
      <PageWithTabsContentSection>
        <StandardSingleColumn id="builds">
          <Link variant="SIMPLE" to={backLink} css={linkCss}>
            <MdArrowBack />
            {buildsByBranchViewText.labels.backToSiteDetails}
          </Link>

          <Spacer size={7} />

          <div>
            <div css={topCss}>
              <div css={infosCss}>
                <Heading as="h1" fontVariant="UI" css={headingCss}>
                  <GoGitPullRequest css={pullRequestIconCss} />
                  <Spacer direction="horizontal" size={3} />
                  {title}
                  <Spacer direction="horizontal" size={3} />
                  {pullRequestNumber && (
                    <span css={pullRequestNumberCss}>#{pullRequestNumber}</span>
                  )}
                </Heading>

                <div css={{ flex: 1 }}>
                  {/** TODO: remove the "else" part of the ternary when pretty URL will reach out */}
                  {pullRequestById?.stableBuildURL ? (
                    <ExternalLink
                      href={pullRequestById?.stableBuildURL}
                      truncate
                      size={2}
                    />
                  ) : (
                    <LastBuildInfo buildId={lastBuildSuccessfulBuild?.id} />
                  )}

                  <Spacer size={3} />

                  {lastBuildSuccessfulBuild?.branch && (
                    <React.Fragment>
                      <SiteBranch>
                        <strong>{lastBuildSuccessfulBuild?.branch}</strong>
                      </SiteBranch>
                      <Spacer size={5} direction="horizontal" />
                    </React.Fragment>
                  )}

                  {lastBuildSuccessfulBuild?.endedAt && (
                    <React.Fragment>
                      <BuildPublishedDate
                        buildPublishedDate={lastBuildSuccessfulBuild.endedAt}
                      />
                      <Spacer size={5} direction="horizontal" />
                    </React.Fragment>
                  )}
                </div>
              </div>

              {organization && (
                <div css={triggerBuildCss}>
                  <TriggerBuild
                    siteId={siteId}
                    pullRequestId={pullRequestId}
                    orgStatus={organization?.status}
                    size={`M`}
                    triggerBuildError={triggerBuildError}
                    setTriggerBuildError={setTriggerBuildError}
                  />
                </div>
              )}
            </div>
            {triggerBuildError}
          </div>

          <Spacer size={5} />

          <h2 css={visuallyHiddenCss}>
            <FormattedMessage<"pullRequestTitle">
              message={deploysViewText.headers.allBuildsOfPR}
              values={{
                pullRequestTitle: title || "",
              }}
            />
          </h2>
          <Feed loading={loadingMore} labelledBy="builds" count={builds.length}>
            <div>
              {builds.map((build, index) => (
                <div key={`build-${build.id}`}>
                  <FeedItem
                    labelledBy={`build-${build.id}`}
                    describedBy={`deploy-status-${build.id}`}
                    position={index + 1}
                    id={build.id}
                  >
                    <BuildCard
                      as="div"
                      id={`build-${build.id}`}
                      siteId={siteId}
                      organizationId={organizationId}
                      pullRequestId={build?.pullRequest?.id}
                      title={
                        build.commit?.message || build.pullRequest?.title || ``
                      }
                      buildId={build.id}
                      status={build.buildStatus}
                      startedAt={build.startedAt}
                      createdAt={build.createdAt}
                      /* backend allows for null. Fallbacking to undefined for safe types in child components */
                      duration={build.duration || undefined}
                      endedAt={build.endedAt}
                      branch={build.branch || ``}
                      /* backend allows for null. Fallbacking to undefined for safe types in child components */
                      commit={build.commit || undefined}
                      /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
                      runnerType={build.runnerType!}
                      /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
                      buildType={build.buildType!}
                      /* backend allows for null. Fallbacking to undefined for safe types in child components */
                      source={build.source || undefined}
                      /* backend allows for null. Fallbacking to undefined for safe types in child components */
                      author={build.author || undefined}
                      isProductionBranch={true}
                      viewDetailsHref={getPathToBuildDetails(
                        build.id,
                        siteId,
                        organizationId
                      )}
                      deployStartedAt={build?.deployStartedAt}
                      deployEndedAt={build?.deployEndedAt}
                      onBuildSucceed={refetch}
                      repositoryUrl={repositoryUrl}
                      routeMetadata={build?.routeMetadata || undefined}
                    />
                  </FeedItem>
                  <Spacer size={5} />
                </div>
              ))}
            </div>
          </Feed>

          {loadingMore && builds.length !== 0 && (
            <div css={{ textAlign: "center" }}>
              <Loading
                variant="baby"
                message={buildsByBranchViewText.messages.loadingBuilds}
              />
            </div>
          )}
          <Waypoint onEnter={loadMore} />
        </StandardSingleColumn>
      </PageWithTabsContentSection>
    </main>
  )
}

/* styles */

const topCss: ThemeCss = theme => ({
  alignItems: `end`,
  display: `grid`,
  gap: theme.space[7],
  marginBottom: theme.space[6],

  [theme.mediaQueries.desktop]: {
    marginBottom: theme.space[8],
    gridTemplateColumns: `1fr auto`,
  },
})

const headingCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[4],
  color: theme.colors.grey[80],
  fontWeight: theme.fontWeights.body,

  [theme.mediaQueries.desktop]: {
    fontSize: theme.fontSizes[5],
  },
})

const infosCss: ThemeCss = theme => ({
  display: `grid`,
  gap: theme.space[4],
  gridTemplateColumns: `minmax(0, 1fr)`,
})
