import * as React from "react"
import { getLinkToCommit } from "@modules/build/shared/utils"
import { BaseAnchor, ThemeCss } from "gatsby-interface"
import { GoGitBranch } from "react-icons/go"
import { BuildCommit } from "@modules/graphql/types"

const baseCss: ThemeCss = theme => ({
  display: `inline-flex`,
  minWidth: 0,
  fontFamily: theme.fonts.monospace,
  fontSize: theme.fontSizes[1],
  color: theme.colors.grey[50],
  lineHeight: theme.lineHeights.default,
  textDecorationColor: theme.colors.grey[30],
})

const commitCss: ThemeCss = theme => ({
  display: `inline-flex`,
  alignItems: `center`,
  lineHeight: theme.lineHeights.dense,
  wordBreak: `break-word`,
  color: theme.colors.grey[50],
  maxWidth: `100%`,

  svg: {
    color: theme.colors.grey[50],
    marginRight: theme.space[2],
  },

  a: {
    textDecoration: `none`,
    color: theme.colors.purple[60],

    "&:hover": {
      textDecoration: `underline`,
    },
  },
})

const linkCss: ThemeCss = theme => ({
  maxWidth: `100%`,
  whiteSpace: `nowrap`,
  textOverflow: `ellipsis`,
  overflowX: `hidden`,

  "&:hover": {
    color: theme.colors.purple[40],
  },

  [theme.mediaQueries.desktop]: {
    maxWidth: `25rem`,
  },
})

export type Commit = {
  sha?: string
  name?: string
  avatarUrl?: string
  message?: string
}

export type BuildCommitInfoProps = {
  branch: string
  commit?: Pick<BuildCommit, "sha">
  repositoryUrl: string
  withLink?: boolean
  withIcon?: boolean
  withBranchName?: boolean
  className?: string
}

export default function BuildCommitInfo({
  branch,
  commit,
  repositoryUrl,
  withLink = false,
  className,
  withBranchName = true,
  withIcon = false,
}: BuildCommitInfoProps) {
  if (!commit) {
    return null
  }

  const commitLink = getLinkToCommit({
    repositoryUrl,
    commitSha: (commit && commit.sha) || "",
  })

  const infoNode = (
    <span data-testid="build-commit-info" css={commitCss}>
      {withIcon && <GoGitBranch />}

      {withLink ? (
        <BaseAnchor
          href={commitLink.url}
          target="_blank"
          aria-label={`View commit ${commitLink.anchorText}`}
          css={linkCss}
        >
          {withBranchName && `${branch}@`}
          {commitLink.anchorText}
        </BaseAnchor>
      ) : (
        <span>
          {withBranchName && `${branch}@`}
          {commitLink.anchorText}
        </span>
      )}
    </span>
  )

  return (
    <span css={baseCss} className={className}>
      {infoNode}
    </span>
  )
}
