import * as React from "react"
import { Card, ThemeCss, Heading } from "gatsby-interface"
import { SiteFunctionEntry } from "@modules/graphql/types"

export type BuildFunctionCardProps = Pick<
  SiteFunctionEntry,
  "id" | "path" | "status" | "siteId"
> & {
  organizationId: string
  buildId: string
  children?: React.ReactNode
  noLink?: boolean
}

export function BuildFunctionCard({ path, children }: BuildFunctionCardProps) {
  return (
    <Card as={`article`} css={cardCss}>
      <Heading fontVariant="UI">{path}</Heading>
      {children}
    </Card>
  )
}

/* styles */

const cardCss: ThemeCss = theme => ({
  padding: `${theme.space[5]} ${theme.space[6]}`,
  display: `grid`,
  alignItems: `center`,
})
