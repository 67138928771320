import * as React from "react"
import * as Yup from "yup"
import { UserInfoInput } from "@modules/graphql/types"
import { ui as uiText } from "@modules/locales/default.js"
import {
  auth as authText,
  loginErrors as errorsText,
} from "@modules/locales/default.js"
import { FormattedMessage } from "@modules/locales"
import { AnonymousFeatureFlagProvider } from "@modules/featureFlags"
import Form from "@modules/form/components/Form"
import { LoginScreenContainer } from "@modules/login/cloud/components/LoginScreen.helpers"
import { Logo } from "@modules/login/cloud/components/logo"
import { WindowLocation } from "@reach/router"
import * as qs from "query-string"
import {
  Heading,
  Link,
  ThemeCss,
  Button,
  InputConnectedField,
  Text,
  Notification,
} from "gatsby-interface"
import { useTracker, SegmentEventType } from "@modules/analytics"
import { LoginOption, LoginOptionLabels } from "@modules/userAccount/constants"

import { isAuthenticated, processLoginAttempt } from "@modules/auth"
import { ErrorAlert } from "@modules/ui/components/ErrorAlert"

const mainCss: ThemeCss = theme => ({
  textAlign: `center`,
  display: `flex`,
  flexDirection: `column`,
  justifyContent: `space-between`,
  minHeight: `100vh`,
  paddingTop: theme.space[12],
  paddingBottom: theme.space[7],
  position: `relative`,
  zIndex: 1,
})

const textCss: ThemeCss = theme => ({
  color: theme.colors.grey[60],
  margin: 0,
})

const sectionCss: ThemeCss = theme => ({
  flexGrow: 1,
  display: `flex`,
  flexDirection: `column`,
})

const topSectionCss: ThemeCss = theme => [
  sectionCss(theme),
  {
    justifyContent: `space-between`,
  },
]

const logoContainerCss: ThemeCss = theme => ({
  display: `flex`,
  justifyContent: `center`,
})

const titleCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[9],
  fontWeight: theme.fontWeights.extraBold,
  marginTop: 0,
  marginBottom: theme.space[7],
})

const bottomSectionCss: ThemeCss = theme => [
  sectionCss(theme),
  {
    justifyContent: `flex-end`,
  },
]

const ssoSectionCss: ThemeCss = theme => [
  sectionCss(theme),
  {
    paddingTop: theme.space[0],
  },
]

const ctaBlockCss: ThemeCss = theme => ({
  paddingTop: theme.space[6],
  paddingBottom: theme.space[9],
  paddingLeft: theme.space[5],
  paddingRight: theme.space[5],
  display: `grid`,
  gridTemplateColumns: `minmax(100%, 360px)`,
  gridGap: theme.space[5],
  margin: `0 auto`,
  [theme.mediaQueries.phablet]: {
    gridTemplateColumns: `360px`,
  },
  justifyContent: `center`,
})

const errorBlockCss: ThemeCss = theme => [
  sectionCss(theme),
  {
    justifyContent: `center`,
    display: `flex`,
    margin: `0 auto`,
    paddingLeft: theme.space[5],
    paddingRight: theme.space[5],
  },
]

const ssoTestSuccessBlockCss: ThemeCss = theme => [
  sectionCss(theme),
  {
    justifyContent: `center`,
    display: `flex`,
    margin: `0 auto`,
    paddingLeft: theme.space[5],
    paddingRight: theme.space[5],
  },
]

export const validationSchema = Yup.object<SSOFormValues>().shape({
  email: Yup.string()
    .email(uiText.messages.validationInvalidEmail)
    .required(uiText.messages.validationIsRequired),
})

export type SSOFormValues = UserInfoInput

export type SSOScreenProps = {
  location?: WindowLocation
}

export function SSOLoginPage({ location }: SSOScreenProps) {
  const { trackSegment, trackAction } = useTracker()
  const query = qs.parse(location?.search || "")
  const error = query.error
  const ssoLoginTest = query.ssoLoginTest
  return (
    <AnonymousFeatureFlagProvider>
      <LoginScreenContainer>
        <main css={mainCss}>
          <div css={topSectionCss}>
            <header css={logoContainerCss}>
              <Logo />
            </header>

            <Heading as="h1" css={titleCss}>
              {authText.headers.sso}
            </Heading>

            {error && (
              <div css={errorBlockCss}>
                <ErrorAlert>{errorsText.messages.ssoError}</ErrorAlert>
              </div>
            )}
            {ssoLoginTest && ssoLoginTest === `success` && (
              <div css={ssoTestSuccessBlockCss}>
                <Notification
                  variant="SECONDARY"
                  tone="SUCCESS"
                  content={<div>{authText.messages.ssoLoginTestSuccess}</div>}
                />
              </div>
            )}
          </div>

          <div css={ctaBlockCss}>
            <Form<SSOFormValues>
              validateOnBlur={true}
              enableReinitialize={true}
              initialValues={{
                email: ``,
              }}
              validationSchema={validationSchema}
              onSubmit={values => {
                trackAction({
                  eventType: `TRACK_EVENT`,
                  name: `Clicked Authenticate via WorkOs`,
                  uiSource: `Login page`,
                })
                trackSegment({
                  type: SegmentEventType.Track,
                  event: `Clicked to Autneticate via WorkOs`,
                  properties: {
                    loginType: LoginOptionLabels.WORKOS,
                  },
                })
                const authType = isAuthenticated()
                  ? `SSO_VERIFY_AUTH`
                  : `DASHBOARD_AUTH`
                processLoginAttempt(
                  `idpcontact=${values.email}&authType=${authType}`,
                  LoginOption.Workos
                )
              }}
            >
              {({ values }) => (
                <Form.FormElement applySpacing noValidate>
                  <InputConnectedField
                    aria-label="email"
                    label=""
                    name="email"
                    placeholder={authText.labels.enterYourEmail}
                    required
                  />
                  <Button type="submit" size="M" width="FIT_CONTAINER">
                    {authText.actions.continueToProvider}
                  </Button>
                </Form.FormElement>
              )}
            </Form>
          </div>

          <div css={ssoSectionCss}>
            <Text size="M" css={textCss}>
              {authText.messages.notUsingSSO}
            </Text>
            <Text size="M" css={textCss}>
              <FormattedMessage<never, "link">
                message={authText.messages.backToLogin}
                tags={{
                  link: function(content) {
                    return <Link to="/dashboard/login">{content}</Link>
                  },
                }}
              />
            </Text>
          </div>

          <div css={bottomSectionCss}>
            <Text size="M" css={textCss}>
              <FormattedMessage<never, "link">
                message={authText.messages.noAccount}
                tags={{
                  link: function(content) {
                    return <Link to="/dashboard/signup">{content}</Link>
                  },
                }}
              />
            </Text>
          </div>
        </main>
      </LoginScreenContainer>
    </AnonymousFeatureFlagProvider>
  )
}
