import * as React from "react"
import { MdArrowForward, MdAdd } from "react-icons/md"
import * as qs from "query-string"
import { RouteComponentProps } from "@reach/router"
import Form from "@modules/form/components/Form"
import { leadCapture as text } from "@modules/locales/default.js"
import {
  Button,
  InputConnectedField,
  RadioButtonConnectedField,
  SelectConnectedField,
  Heading,
  Text,
  ThemeCss,
} from "gatsby-interface"
import {
  validationSchema,
  projectTypeOptions,
  LeadCaptureFormValues,
  getCountryOptions,
  getRegionOptions,
  AvailableRegions,
} from "./LeadCapture.helpers"
import { FullStoryClassName } from "../../../fullStory/constants"
import { useTracker, GAEventType } from "@modules/analytics"
import { User, MachinePricingTier } from "@modules/graphql/types"
import { visuallyHiddenCss } from "@modules/a11y/stylesheets"
import { FormattedMessage, interpolateMessage } from "@modules/locales"
import { DASHBOARD_HEADER_HEIGHT } from "@modules/ui/layouts/constants"
import {
  LeadCaptureBackgroundLeft,
  LeadCaptureBackgroundRight,
} from "../assets/LeadCaptureBackground"
import {
  SupportedPlanTier,
  supportedPlans,
  TRIAL_DAYS,
} from "@modules/billing/constants"
import {
  getColorsForPlanTier,
  normalizePlanTier,
} from "@modules/billing/shared/utils"
import { useFlags } from "@modules/featureFlags"
import { PlanTierInfo } from "@modules/billing/shared/components/PlanTierInfo"
import { SupportedPricingTier } from "@modules/billing/shared/constants/tiers"
import capitalizeStr from "@modules/toolkit/helpers/capitalizeStr"
import { useUtmFields, UtmLeadCaptureValues } from "../hooks/useUtmFields"

const baseCss: ThemeCss = theme => ({
  width: `100%`,
  minHeight: `calc(100vh - ${DASHBOARD_HEADER_HEIGHT})`,
  display: `flex`,
  alignItems: `center`,
  justifyContent: `center`,
  paddingTop: theme.space[8],
  paddingBottom: theme.space[8],
  paddingLeft: theme.space[7],
  paddingRight: theme.space[7],
  [theme.mediaQueries.desktop]: {
    paddingLeft: 0,
    paddingRight: 0,
  },
})

const containerCss: ThemeCss = theme => ({
  display: `flex`,
  flexWrap: `wrap`,
  flexDirection: `row`,
  alignItems: `center`,
  justifyContent: `start`,
  [theme.mediaQueries.desktop]: {
    flexWrap: `nowrap`,
    alignItems: `stretch`,
    justifyContent: `center`,
  },
})

const planInfoCss: ThemeCss = theme => ({
  [theme.mediaQueries.desktop]: {
    paddingRight: theme.space[11],
  },
})

const titleCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[9],
  letterSpacing: "-0.02em",
  fontWeight: theme.fontWeights.extraBold,
  maxWidth: `21.25rem`,
})

const cloudCss: ThemeCss = theme => ({
  color: theme.colors.gatsby,
  background: `-webkit-linear-gradient(${theme.colors.blue[60]}, ${theme.colors.gatsby})`,
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
})

const subTitleCss: ThemeCss = theme => ({
  display: `block`,
  fontFamily: theme.fonts.heading,
  fontSize: theme.fontSizes[4],
  fontWeight: theme.fontWeights.body,
  color: theme.colors.grey[70],
  margin: 0,
  letterSpacing: `normal`,
})

const featuresHeader: ThemeCss = theme => ({
  marginTop: theme.space[8],
  marginBottom: theme.space[4],
  color: theme.colors.black,
  fontSize: theme.fontSizes[2],
  lineHeight: theme.lineHeights.default,
  fontFamily: theme.fonts.body,
})

const featuresListCss: ThemeCss = theme => ({
  listStyle: `none`,
  marginTop: 0,
  marginBottom: theme.space[7],
  marginLeft: 0,
  marginRight: 0,
  padding: 0,
  background: theme.colors.white,
  // So that it doesn't cutoff background image on small screens
  display: `inline-block`,
})

const formContainerCss: ThemeCss = theme => ({
  padding: theme.space[7],
  boxShadow: theme.shadows.dialog,
  borderRadius: theme.radii[3],
  backgroundColor: theme.colors.white,
  flex: `1 1 100%`,
  [theme.mediaQueries.desktop]: {
    flex: `0 0 26rem`,
  },
})

const formCss: ThemeCss = () => ({
  maxWidth: `100%`,
})

const projectTypeLabelCss: ThemeCss = theme => ({
  display: "flex",
  textAlign: "center",
  justifyContent: "center",
  fontFamily: theme.fonts.heading,
  fontSize: theme.fontSizes[3],
  fontWeight: theme.fontWeights.body,
  lineHeight: theme.lineHeights.solid,
  color: theme.colors.grey[60],
  marginTop: theme.space[6],
  marginBottom: 0,
  position: "relative",
  width: "100%",
  ":before": {
    borderTop: `1px solid ${theme.colors.grey[30]}`,
    content: '""',
    height: "0",
    left: "0",
    top: "0.5em",
    position: "absolute",
    width: "100%",
  },
})

const projectTypeLabelTextCss: ThemeCss = theme => ({
  display: "flex",
  alignItems: "center",
  background: theme.colors.primaryBackground,
  padding: `0 ${theme.space[7]}`,
  position: "relative",
})

const trialEndHintCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[0],
  lineHeight: theme.lineHeights.default,
  color: theme.colors.grey[60],
  marginTop: 0,
  marginBottom: theme.space[3],
})

const tierInfosCss: ThemeCss = theme => ({
  display: `grid`,
  rowGap: theme.space[6],
  marginTop: theme.space[6],
  marginBottom: theme.space[8],
})

type LeadCaptureFormProps = RouteComponentProps & {
  userName: string
  freePlanName: string
  userSelectedPlan?: boolean
  selectedPlan?: SupportedPlanTier | string
  isSelectedMultiplePlanFree?: boolean
  organizationId?: string
  utmFields?: UtmLeadCaptureValues
  referrerUrl?: string
  onSubmit?: <T>(values: LeadCaptureFormValues) => void | Promise<T>
}

function LeadCaptureForm({
  userName,
  freePlanName,
  selectedPlan,
  isSelectedMultiplePlanFree,
  utmFields,
  referrerUrl,
  onSubmit,
}: LeadCaptureFormProps) {
  const { trackButtonClicked, trackGoogleAnalytics } = useTracker()

  const isFreePlan = !selectedPlan || selectedPlan === SupportedPlanTier.Free
  const showTrialEndDisclaimer = !isFreePlan
  return (
    <Form<LeadCaptureFormValues>
      validateOnBlur={true}
      enableReinitialize={true}
      initialValues={{
        firstName: userName ? userName.substr(0, userName.indexOf(` `)) : ``,
        lastName: userName ? userName.substr(userName.indexOf(` `) + 1) : ``,
        companyEmail: ``,
        projectType: ``,
        country: ``,
        region: ``,
        referrerUrl,
        ...utmFields,
      }}
      validationSchema={validationSchema}
      onSubmit={values => {
        trackButtonClicked(`update welcome fields`, {
          uiSource: `Welcome form`,
        })

        trackGoogleAnalytics({
          eventType: GAEventType.Click,
          category: `Signup Flow`,
          label: {
            loc: `Lead Capture Page`,
            text: `Submitted welcome form on /dashboard/welcome. arm: expA`,
          },
        })
        if (onSubmit) onSubmit(values)
      }}
    >
      {({ values }) => (
        <Form.FormElement css={formCss} applySpacing noValidate>
          <InputConnectedField
            className={FullStoryClassName.Block}
            label={text.labels.firstName}
            name="firstName"
            required
          />
          <InputConnectedField
            className={FullStoryClassName.Block}
            label={text.labels.lastName}
            name="lastName"
            required
          />
          <InputConnectedField
            className={FullStoryClassName.Block}
            label={text.labels.workEmail}
            name="companyEmail"
            type="email"
            required
          />
          <SelectConnectedField
            className={FullStoryClassName.Block}
            label={text.labels.country}
            name="country"
            options={getCountryOptions()}
            required
          />
          {values.country && AvailableRegions[values.country] && (
            <SelectConnectedField
              className={FullStoryClassName.Block}
              label={text.labels.region}
              name="region"
              options={getRegionOptions(values.country)}
              required
            />
          )}

          <div css={projectTypeLabelCss}>
            <span css={projectTypeLabelTextCss}>{text.labels.projectType}</span>
          </div>
          <RadioButtonConnectedField
            name="projectType"
            options={projectTypeOptions}
            label={
              <span css={visuallyHiddenCss}>{text.labels.projectType}</span>
            }
          />

          {showTrialEndDisclaimer && (
            <Text css={trialEndHintCss}>
              <FormattedMessage<
                "trialDays" | "freeTierPlanName" | "planName",
                "strong"
              >
                message={text.messages.trialEndDisclaimer}
                values={{
                  planName: freePlanName,
                  freeTierPlanName: freePlanName,
                  trialDays: TRIAL_DAYS,
                }}
                tags={{
                  strong: function(content) {
                    return <strong>{content}</strong>
                  },
                }}
              />
            </Text>
          )}

          <Button
            type="submit"
            rightIcon={<MdArrowForward />}
            size="M"
            width="FIT_CONTAINER"
          >
            {isFreePlan || isSelectedMultiplePlanFree
              ? text.actions.proceedFreeTier
              : text.actions.proceedTrial}
          </Button>
        </Form.FormElement>
      )}
    </Form>
  )
}

type LeadCaptureProps = RouteComponentProps & {
  user: Partial<User>
  onSubmit?: <T>(values: LeadCaptureFormValues) => void | Promise<T>
}

export function LeadCapture({ user, location, onSubmit }: LeadCaptureProps) {
  const { flags, ready } = useFlags()

  const queryParam = qs.parse(location?.search || "")
  const utmFields = useUtmFields()
  const referrerUrl =
    typeof window !== undefined
      ? encodeURIComponent(window.document.referrer)
      : ""

  const organizationId = queryParam.organizationId as string | undefined
  const selectedPlan = (queryParam.selectedPlan || "") as string
  const userSelectedPlan = selectedPlan !== ""

  const selectedBuildsTier = (queryParam.selectedBuildsTier ||
    `FREE`) as SupportedPricingTier
  const selectedHostingTier = (queryParam.selectedHostingTier ||
    `FREE`) as SupportedPricingTier
  const selectedBuildsName = capitalizeStr({
    str: selectedBuildsTier.toLowerCase(),
  })
  const selectedHostingName = capitalizeStr({
    str: selectedHostingTier.toLowerCase(),
  })

  const selectedMultiplePlan = `${selectedBuildsName} Builds - ${selectedHostingName} Hosting`
  const isSelectedMultiplePlanFree =
    selectedBuildsTier === MachinePricingTier.Free &&
    selectedHostingTier === MachinePricingTier.Free

  const selectedPlanTier =
    normalizePlanTier(selectedPlan) || SupportedPlanTier.Free
  const isFreePlan = selectedPlanTier === SupportedPlanTier.Free
  const planInfo = supportedPlans[selectedPlanTier]

  const getPlanColors = getColorsForPlanTier(selectedPlanTier)
  const planColorCss: ThemeCss = theme => ({
    color: getPlanColors(theme).primaryColor,
  })

  const featureIconCss: ThemeCss = theme => [
    {
      marginRight: theme.space[3],
      fontSize: theme.fontSizes[3],
      verticalAlign: `middle`,
    },
    planColorCss(theme),
  ]

  if (!ready) {
    return null
  }

  return (
    <main css={baseCss}>
      <LeadCaptureBackgroundLeft
        css={{ position: `absolute`, left: 0, bottom: 0, zIndex: -1 }}
      />
      <LeadCaptureBackgroundRight
        css={{ position: `absolute`, right: 0, zIndex: -1 }}
      />
      <div css={containerCss}>
        <div css={planInfoCss}>
          <Heading as="h1" css={titleCss}>
            {isFreePlan && isSelectedMultiplePlanFree ? (
              <FormattedMessage<never, "strong">
                message={text.headers.welcomeFreeTier}
                tags={{
                  strong: function(content) {
                    return <span css={cloudCss}>{content}</span>
                  },
                }}
              />
            ) : (
              <FormattedMessage<"trialingPlanName" | "trialDays", "strong">
                message={text.headers.welcomeTrial}
                values={{
                  trialingPlanName: planInfo.name,
                  trialDays: TRIAL_DAYS,
                }}
                tags={{
                  strong: function(content) {
                    return flags.multiTiersPlans ? null : (
                      <span css={planColorCss}>{content}</span>
                    )
                  },
                }}
              />
            )}
            {!isFreePlan ||
              (flags.multiTiersPlans && !isSelectedMultiplePlanFree && (
                <small css={subTitleCss}>
                  {text.messages.noCreditCardRequired}
                </small>
              ))}
          </Heading>
          <Heading as="h2" css={featuresHeader}>
            {isFreePlan && isSelectedMultiplePlanFree
              ? interpolateMessage<"freeTierPlanName">(
                  text.headers.whatsIncludedFreeTier,
                  { freeTierPlanName: planInfo.name }
                )
              : text.headers.whatsIncludedTrial}
          </Heading>
          {flags.multiTiersPlans ? (
            <div css={tierInfosCss}>
              <PlanTierInfo
                tierType="builds"
                tier={selectedBuildsTier}
                showDetails={true}
              />
              <PlanTierInfo
                tierType="hosting"
                tier={selectedHostingTier}
                showDetails={true}
              />
            </div>
          ) : (
            <ul css={featuresListCss}>
              {planInfo.features.map(feature => (
                <li key={feature}>
                  <MdAdd css={featureIconCss} aria-hidden />
                  {feature}
                </li>
              ))}
            </ul>
          )}
        </div>
        <div css={formContainerCss}>
          <LeadCaptureForm
            userSelectedPlan={userSelectedPlan}
            selectedPlan={
              selectedMultiplePlan ? selectedMultiplePlan : selectedPlanTier
            }
            isSelectedMultiplePlanFree={isSelectedMultiplePlanFree}
            organizationId={organizationId}
            userName={user?.name || ""}
            utmFields={utmFields}
            referrerUrl={referrerUrl}
            freePlanName={supportedPlans.Free.name}
            location={location}
            onSubmit={onSubmit}
          />
        </div>
      </div>
    </main>
  )
}
