import * as Yup from "yup"
import { UserWelcomeInput } from "@modules/graphql/types"
import { leadCapture as text, ui as uiText } from "@modules/locales/default.js"
const countryRegionData = require("../utils/countrySelectorData.json")

export type LeadCaptureFormValues = UserWelcomeInput

export type RegionData = {
  name: string
  shortCode: string
}

export type CountryData = {
  countryName: string
  countryShortCode: string
  regions?: Array<RegionData>
}

export const AvailableRegions: { [region: string]: boolean } = {
  US: true,
  CA: true,
  AU: true,
}

// export type CountryCode = keyof typeof countryRegionData

export const validationSchema = Yup.object<LeadCaptureFormValues>().shape({
  firstName: Yup.string().required(uiText.messages.validationIsRequired),
  lastName: Yup.string().required(uiText.messages.validationIsRequired),
  companyEmail: Yup.string()
    .email(uiText.messages.validationInvalidEmail)
    .required(uiText.messages.validationIsRequired),
  projectType: Yup.string().required(uiText.messages.validationIsRequired),
  country: Yup.string().required(uiText.messages.validationIsRequired),
  region: Yup.string().when("country", (country: string) => {
    // Only required if region is available for selected country
    if (AvailableRegions[country]) {
      return Yup.string().required(uiText.messages.validationIsRequired)
    }
  }),
})

export const ProjectType = {
  CompanyProject: `COMPANY_PROJECT`,
  ClientProject: `CLIENT_PROJECT`,
  PersonalProject: `PERSONAL_PROJECT`,
}

export const projectTypeOptions = [
  {
    label: text.labels.projectTypeCompany,
    value: ProjectType.CompanyProject,
  },
  {
    label: text.labels.projectTypeClient,
    value: ProjectType.ClientProject,
  },
  {
    label: text.labels.projectTypePersonal,
    value: ProjectType.PersonalProject,
  },
]

export const getCountryOptions = () => {
  const initial = { label: `Select a country`, value: `` }

  return [
    initial,
    ...countryRegionData.map((country: CountryData) => ({
      label: country.countryName,
      value: country.countryShortCode,
    })),
  ]
}

export const getRegionOptions = (countryShortCode: string) => {
  if (!AvailableRegions[countryShortCode]) return []

  const initial = { label: `Select a Region`, value: `` }
  const { regions } = countryRegionData.find(
    (country: CountryData) => country.countryShortCode === countryShortCode
  )

  return regions
    ? [
        initial,
        ...regions.map((region: RegionData) => ({
          label: region.name,
          value: region.shortCode,
        })),
      ]
    : []
}
