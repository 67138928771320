// THIS IS A GENERATED FILE
import * as Types from "../graphql/types"

import { BillingPlanFieldsFragment } from "../organization/fragments.generated"
import gql from "graphql-tag"
import { BillingPlanFieldsFragmentDoc } from "../organization/fragments.generated"
import * as ApolloReactCommon from "@apollo/react-common"
import * as ApolloReactHooks from "@apollo/react-hooks"

export type AvailablePlansQueryVariables = Types.Exact<{
  organizationId?: Types.Maybe<Types.Scalars["UUID"]>
  interval: Types.BillingInterval
  group?: Types.Maybe<Types.Scalars["String"]>
}>

export type AvailablePlansQuery = { __typename?: "Query" } & {
  availableSubscriptionPlans?: Types.Maybe<
    Array<
      Types.Maybe<
        { __typename?: "SubscriptionPlan" } & Pick<
          Types.SubscriptionPlan,
          | "isRecommended"
          | "nextBillingDate"
          | "formattedMonthlyAmount"
          | "quantity"
          | "buildsTier"
          | "hostingTier"
        > &
          BillingPlanFieldsFragment
      >
    >
  >
}

export type AllAvailablePlansQueryVariables = Types.Exact<{
  organizationId?: Types.Maybe<Types.Scalars["UUID"]>
  group?: Types.Maybe<Types.Scalars["String"]>
}>

export type AllAvailablePlansQuery = { __typename?: "Query" } & {
  monthlyPlans?: Types.Maybe<
    Array<
      Types.Maybe<
        { __typename?: "SubscriptionPlan" } & Pick<
          Types.SubscriptionPlan,
          | "isRecommended"
          | "nextBillingDate"
          | "formattedMonthlyAmount"
          | "quantity"
          | "buildsTier"
          | "hostingTier"
        > &
          BillingPlanFieldsFragment
      >
    >
  >
  annualPlans?: Types.Maybe<
    Array<
      Types.Maybe<
        { __typename?: "SubscriptionPlan" } & Pick<
          Types.SubscriptionPlan,
          | "isRecommended"
          | "nextBillingDate"
          | "formattedMonthlyAmount"
          | "quantity"
          | "buildsTier"
          | "hostingTier"
        > &
          BillingPlanFieldsFragment
      >
    >
  >
}

export type ChangePlanMutationVariables = Types.Exact<{
  organizationId: Types.Scalars["UUID"]
  nextPlanId: Types.Scalars["UUID"]
}>

export type ChangePlanMutation = { __typename?: "Mutation" } & {
  changePlan?: Types.Maybe<
    { __typename?: "MutationResult" } & Pick<
      Types.MutationResult,
      "success" | "message"
    >
  >
}

export type CancelPlanMutationVariables = Types.Exact<{
  organizationId: Types.Scalars["UUID"]
}>

export type CancelPlanMutation = { __typename?: "Mutation" } & {
  cancelPlan?: Types.Maybe<
    { __typename?: "MutationResult" } & Pick<
      Types.MutationResult,
      "success" | "message"
    >
  >
}

export const AvailablePlansDocument = gql`
  query availablePlans(
    $organizationId: UUID
    $interval: BillingInterval!
    $group: String
  ) {
    availableSubscriptionPlans(
      organizationId: $organizationId
      interval: $interval
      group: $group
    ) {
      ...billingPlanFields
      isRecommended
      nextBillingDate
      formattedMonthlyAmount
      quantity
      buildsTier
      hostingTier
    }
  }
  ${BillingPlanFieldsFragmentDoc}
`

/**
 * __useAvailablePlansQuery__
 *
 * To run a query within a React component, call `useAvailablePlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailablePlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailablePlansQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      interval: // value for 'interval'
 *      group: // value for 'group'
 *   },
 * });
 */
export function useAvailablePlansQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AvailablePlansQuery,
    AvailablePlansQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    AvailablePlansQuery,
    AvailablePlansQueryVariables
  >(AvailablePlansDocument, baseOptions)
}
export function useAvailablePlansLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AvailablePlansQuery,
    AvailablePlansQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    AvailablePlansQuery,
    AvailablePlansQueryVariables
  >(AvailablePlansDocument, baseOptions)
}
export type AvailablePlansQueryHookResult = ReturnType<
  typeof useAvailablePlansQuery
>
export type AvailablePlansLazyQueryHookResult = ReturnType<
  typeof useAvailablePlansLazyQuery
>
export type AvailablePlansQueryResult = ApolloReactCommon.QueryResult<
  AvailablePlansQuery,
  AvailablePlansQueryVariables
>
export const AllAvailablePlansDocument = gql`
  query allAvailablePlans($organizationId: UUID, $group: String) {
    monthlyPlans: availableSubscriptionPlans(
      organizationId: $organizationId
      interval: MONTHLY
      group: $group
    ) {
      ...billingPlanFields
      isRecommended
      nextBillingDate
      formattedMonthlyAmount
      quantity
      buildsTier
      hostingTier
    }
    annualPlans: availableSubscriptionPlans(
      organizationId: $organizationId
      interval: ANNUAL
      group: $group
    ) {
      ...billingPlanFields
      isRecommended
      nextBillingDate
      formattedMonthlyAmount
      quantity
      buildsTier
      hostingTier
    }
  }
  ${BillingPlanFieldsFragmentDoc}
`

/**
 * __useAllAvailablePlansQuery__
 *
 * To run a query within a React component, call `useAllAvailablePlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllAvailablePlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllAvailablePlansQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      group: // value for 'group'
 *   },
 * });
 */
export function useAllAvailablePlansQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AllAvailablePlansQuery,
    AllAvailablePlansQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    AllAvailablePlansQuery,
    AllAvailablePlansQueryVariables
  >(AllAvailablePlansDocument, baseOptions)
}
export function useAllAvailablePlansLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AllAvailablePlansQuery,
    AllAvailablePlansQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    AllAvailablePlansQuery,
    AllAvailablePlansQueryVariables
  >(AllAvailablePlansDocument, baseOptions)
}
export type AllAvailablePlansQueryHookResult = ReturnType<
  typeof useAllAvailablePlansQuery
>
export type AllAvailablePlansLazyQueryHookResult = ReturnType<
  typeof useAllAvailablePlansLazyQuery
>
export type AllAvailablePlansQueryResult = ApolloReactCommon.QueryResult<
  AllAvailablePlansQuery,
  AllAvailablePlansQueryVariables
>
export const ChangePlanDocument = gql`
  mutation changePlan($organizationId: UUID!, $nextPlanId: UUID!) {
    changePlan(organizationId: $organizationId, nextPlanId: $nextPlanId) {
      success
      message
    }
  }
`
export type ChangePlanMutationFn = ApolloReactCommon.MutationFunction<
  ChangePlanMutation,
  ChangePlanMutationVariables
>

/**
 * __useChangePlanMutation__
 *
 * To run a mutation, you first call `useChangePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePlanMutation, { data, loading, error }] = useChangePlanMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      nextPlanId: // value for 'nextPlanId'
 *   },
 * });
 */
export function useChangePlanMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ChangePlanMutation,
    ChangePlanMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ChangePlanMutation,
    ChangePlanMutationVariables
  >(ChangePlanDocument, baseOptions)
}
export type ChangePlanMutationHookResult = ReturnType<
  typeof useChangePlanMutation
>
export type ChangePlanMutationResult = ApolloReactCommon.MutationResult<
  ChangePlanMutation
>
export type ChangePlanMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ChangePlanMutation,
  ChangePlanMutationVariables
>
export const CancelPlanDocument = gql`
  mutation cancelPlan($organizationId: UUID!) {
    cancelPlan(organizationId: $organizationId) {
      success
      message
    }
  }
`
export type CancelPlanMutationFn = ApolloReactCommon.MutationFunction<
  CancelPlanMutation,
  CancelPlanMutationVariables
>

/**
 * __useCancelPlanMutation__
 *
 * To run a mutation, you first call `useCancelPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelPlanMutation, { data, loading, error }] = useCancelPlanMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useCancelPlanMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CancelPlanMutation,
    CancelPlanMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CancelPlanMutation,
    CancelPlanMutationVariables
  >(CancelPlanDocument, baseOptions)
}
export type CancelPlanMutationHookResult = ReturnType<
  typeof useCancelPlanMutation
>
export type CancelPlanMutationResult = ApolloReactCommon.MutationResult<
  CancelPlanMutation
>
export type CancelPlanMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CancelPlanMutation,
  CancelPlanMutationVariables
>
