import * as React from "react"
import * as qs from "query-string"
import { WindowLocation } from "@reach/router"
import {
  Link,
  Card,
  Heading,
  CardSection,
  ThemeCss,
  MonogramIcon,
} from "gatsby-interface"
import { MdLock } from "react-icons/md"
import { authenticatePreview } from "@modules/auth"
import { LoginRoot, LogoLink, Content } from "../../shared/components/Layout"
import { previewLogin as text } from "@modules/locales/default.js"
import { CornerDots } from "@modules/decorative"
import { useTriggerErrorAlert } from "@modules/ui/components/ErrorAlert"
import { rot13 } from "simple-rot13"
import { setIdentify } from "../../../fullStory"
import { useTracker } from "@modules/analytics"
import { PreviewPasswordForm } from "./PreviewPasswordForm"
import { PreviewProtectionType } from "@modules/graphql/types"
import { QueryError } from "@modules/login/cloud/components/Errors.helpers"
import { LoginButtons } from "@modules/login/shared/components/LoginButtons"

const contentCss: ThemeCss = theme => ({
  paddingBottom: theme.space[9],
  zIndex: 1,
})

const lockIconCss: ThemeCss = theme => ({
  color: theme.colors.purple[50],
  fontSize: theme.fontSizes[4],
  verticalAlign: `text-bottom`,
})

const cardCss: ThemeCss = _theme => ({
  margin: "0 auto",
  maxWidth: "100%",
  width: "28rem",
})

const authButtonsCss: ThemeCss = theme => ({
  display: `grid`,
  gridGap: theme.space[5],
})

const linksContainerCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[1],
  justifyContent: `center`,
  marginTop: theme.space[5],
  textAlign: `center`,
})

const linkCss: ThemeCss = theme => ({
  "&:after": {
    content: `"|"`,
    display: `inline-block`,
    color: theme.colors.grey[50],
    margin: theme.space[4],
  },
  "&:last-of-type:after": {
    content: `""`,
  },
})

export type PreviewLoginProps = {
  location: WindowLocation
}

export function PreviewLogin({ location }: PreviewLoginProps) {
  const query = qs.parse(location.search)

  const loginRedirectUrl = query.loginRedirectUrl as string
  const hostname = getHostnameFromParam(loginRedirectUrl)
  const protectionType = query.protectionType || PreviewProtectionType.CloudAuth
  const error = useTrackAndRedirect(query, loginRedirectUrl)
  const cloudAuthRequired = protectionType === PreviewProtectionType.CloudAuth
  const [setError, errorAlert] = useTriggerErrorAlert()

  React.useEffect(() => {
    if (error) {
      setError(new Error(text.messages.noPermission))
    }
  }, [error])

  return (
    <LoginRoot
      css={theme => ({
        background: theme.colors.secondaryBackground,
      })}
    >
      <CornerDots size="medium" corner="bottom-right" />
      <LogoLink to="/" aria-label="gatsbyjs.com link">
        <MonogramIcon size="medium" />
      </LogoLink>
      <Content css={contentCss}>
        <Card css={cardCss}>
          <CardSection>
            <Heading
              fontVariant="UI"
              css={theme => ({ fontSize: theme.fontSizes[3] })}
            >
              <MdLock css={lockIconCss} />{" "}
              {cloudAuthRequired
                ? text.headers.cloudAuthForm
                : text.headers.passwordAuthForm}
            </Heading>
          </CardSection>
          {errorAlert}
          <CardSection applyPaddingTop={false}>
            {cloudAuthRequired ? (
              <div css={authButtonsCss}>
                <LoginButtons
                  actionType="login"
                  onLoginOptionClick={loginOption => {
                    authenticatePreview(location.search, loginOption)
                  }}
                />
              </div>
            ) : (
              <PreviewPasswordForm
                hostname={hostname}
                loginRedirectUrl={loginRedirectUrl}
                onError={setError}
              />
            )}
          </CardSection>
        </Card>
        <div css={linksContainerCss}>
          <Link
            variant="SIMPLE"
            href="https://www.gatsbyjs.com/preview"
            target="_blank"
            rel="noreferrer noopener"
            css={linkCss}
          >
            {text.labels.gatsbyPreviewLink}
          </Link>
          <Link
            variant="SIMPLE"
            href="https://support.gatsbyjs.com/hc/"
            target="_blank"
            rel="noreferrer noopener"
            css={linkCss}
          >
            {text.labels.documentationLink}
          </Link>
          {` `}
        </div>
      </Content>
    </LoginRoot>
  )
}

function getHostnameFromParam(url: string) {
  const urlObj = new URL(url)
  const hostname = urlObj.hostname
  return hostname
}

const useTrackAndRedirect = (
  query: qs.ParsedQuery<string>,
  loginRedirectUrl: string
) => {
  const { trackPageViewed } = useTracker()
  const token = query.token
  const authError = query.error && query.error === QueryError.PreviewAccessError

  React.useEffect(() => {
    trackPageViewed(`Preview Log In Page`)

    if (token) {
      const name = rot13(decodeURIComponent(query.contact as string))
      const userId = rot13(query.contactId)
      const email = rot13(decodeURIComponent(query.loc as string))

      setIdentify({
        id: userId,
        name,
        email,
      })

      document.cookie = `PreviewAuth=${token};max-age=86400;domain=${process.env.GATSBY_PREVIEW_DOMAIN};path=/`
      return window.location.assign(loginRedirectUrl)
    }
  }, [token])

  return authError
}
