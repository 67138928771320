import React, { useState, useCallback } from "react"
import {
  useStripe,
  useElements,
  CardNumberElement,
} from "@stripe/react-stripe-js"
import { MdArrowForward } from "react-icons/md"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { keyframes } from "@emotion/core"
import { billing as text } from "@modules/locales/default.js"
import {
  Button,
  InputConnectedField,
  SelectConnectedField,
  Heading as BaseHeading,
} from "gatsby-interface"
import Amex from "../assets/Amex"
import Discover from "../assets/Discover"
import Mastercard from "../assets/Mastercard"
import Visa from "../assets/Visa"
import { useTracker } from "@modules/analytics"
import {
  trackCardValidation,
  validateForm,
  subscribePlan,
  updatePayment,
  CC_VALID_VALUE,
  COUNTRY_CODES,
} from "./CheckoutForm.helpers"
import StripeElements from "./StripeElements"
import Form from "../../form/components/Form"

const entry = keyframes({
  "100%": {
    opacity: 1,
  },
})

function Heading(props) {
  return (
    <BaseHeading
      as="h3"
      css={theme => ({
        fontSize: theme.fontSizes[2],
        marginTop: theme.space[7],
      })}
      fontVariant="UI"
      {...props}
    />
  )
}

const formCss = _theme => ({
  animation: `${entry} 0.75s ease forwards`,
  marginBottom: 0,
  opacity: 0,
  width: `100%`,
})

const Header = styled(`section`)({
  alignItems: `center`,
  display: `flex`,
  justifyContent: `space-between`,
})

const CardIcons = styled(`div`)(props => ({
  alignSelf: `flex-end`,
  display: `flex`,
  "> *": {
    marginLeft: props.theme.space[3],
  },
}))

const ContentBlock = styled(`section`)({
  display: `flex`,
  flexWrap: `wrap`,
  justifyContent: `space-between`,
  width: `100%`,
})

const Actions = styled(`div`)(props => ({
  display: `flex`,
  flexDirection: `row`,
  justifyContent: `space-between`,
  marginTop: props.theme.space[7],
  width: `100%`,

  [props.theme.mediaQueries.tablet]: {
    marginTop: props.theme.space[8],
  },
}))

/** This component is used for the plan change of an existing workspace */
function CheckoutForm({
  children,
  planId,
  mutate,
  organizationId,
  showError,
  showSuccess,
  cancelCallback,
  submitButtonText,
  cancelButtonText,
  checkoutType = `SUBSCRIBE`,
  className,
}) {
  const stripe = useStripe()
  const elements = useElements()

  const { track, trackButtonClicked } = useTracker()
  const [formState, setFormState] = useState({
    cardNumber: ``,
    cardExpiry: ``,
    cardCvc: ``,
  })
  const [disabled, setDisabled] = useState(false)

  const validateCard = useCallback(
    e => {
      trackCardValidation(track, e, organizationId)
      let message = ``
      if (e.error) {
        message = e.error.message ? e.error.message : ``
      }
      setFormState({
        ...formState,
        [e.elementType]: !e.empty && !e.error ? CC_VALID_VALUE : message,
      })
    },
    [formState]
  )

  const validate = values =>
    validateForm(
      values,
      formState,
      setFormState,
      text,
      organizationId,
      track,
      setDisabled
    )

  const submitForm = values => {
    const {
      name,
      company,
      email,
      address_line1,
      address_line2,
      address_city,
      address_state,
      address_zip,
      address_country,
    } = values

    // See: https://stripe.com/docs/js/tokens_sources/create_token?type=cardNumberElement#stripe_create_token-tokenType
    // Since we use a global <PaymentProvider> this applies:
    // If applicable, the Element pulls data from other elements you’ve created on the same instance of Elements to tokenize—you only need to supply one Element as the parameter.
    const cardElement = elements.getElement(CardNumberElement)

    trackButtonClicked(text.startSubscription, {
      organizationId,
      uiSource: `Pricing Page`,
    })

    setDisabled(true)
    if (checkoutType === `SUBSCRIBE`)
      subscribePlan({
        cardElement,
        stripe,
        mutate,
        planId,
        organizationId,
        name,
        email,
        company,
        address_line1,
        address_line2,
        address_city,
        address_state,
        address_zip,
        address_country,
        setDisabled,
        showError,
        showSuccess,
      })
    else
      updatePayment({
        cardElement,
        stripe,
        mutate,
        organizationId,
        name,
        email,
        address_line1,
        address_line2,
        address_city,
        address_state,
        address_zip,
        address_country,
        setDisabled,
        showError,
        showSuccess,
      })
  }

  return (
    <Form
      initialValues={{
        name: ``,
        email: ``,
        company: ``,
        address_line1: ``,
        address_line2: ``,
        address_city: ``,
        address_state: ``,
        address_zip: ``,
        address_country: ``,
      }}
      validate={validate}
      onSubmit={submitForm}
    >
      {({ handleSubmit }) => (
        <Form.FormElement
          aria-labelledby="add-payment-method"
          css={formCss}
          onSubmit={handleSubmit}
          className={className}
          data-cy="checkout-form"
        >
          <Header>
            <Heading css={{ marginTop: 0 }}>
              {text.creditCardInformation}
            </Heading>
            <CardIcons>
              <Visa />
              <Mastercard />
              <Discover />
              <Amex />
            </CardIcons>
          </Header>
          <InputConnectedField
            label={text.name}
            name="name"
            placeholder="Name"
            css={theme => ({
              paddingTop: theme.space[4],
            })}
            required
          />

          <ContentBlock className="fs-block">
            <StripeElements formState={formState} validateCard={validateCard} />
          </ContentBlock>

          <Heading>{text.headers.billingAddress}</Heading>
          <InputConnectedField
            label={text.labels.billingAddress}
            name="address_line1"
            placeholder={text.labels.billingAddress}
            css={theme => ({
              paddingTop: theme.space[4],
            })}
            required
          />
          <InputConnectedField
            label={text.labels.billingAddressLineTwo}
            name="address_line2"
            placeholder={text.labels.billingAddressLineTwo}
            css={theme => ({
              paddingTop: theme.space[4],
            })}
          />
          <InputConnectedField
            label={text.labels.billingCity}
            name="address_city"
            placeholder={text.labels.billingCity}
            css={theme => ({
              paddingTop: theme.space[4],
            })}
            required
          />
          <ContentBlock className="fs-block">
            <InputConnectedField
              label={text.labels.billingState}
              name="address_state"
              placeholder={text.labels.billingState}
              css={theme => ({
                paddingTop: theme.space[4],
                width: `100%`,
                [theme.mediaQueries.tablet]: {
                  paddingRight: theme.space[7],

                  width: `50%`,
                },
              })}
              required
            />
            <InputConnectedField
              label={text.labels.billingZipCode}
              name="address_zip"
              placeholder={text.labels.billingZipCode}
              css={theme => ({
                paddingTop: theme.space[4],
                width: `100%`,
                [theme.mediaQueries.tablet]: {
                  paddingRight: theme.space[7],

                  width: `25%`,
                },
              })}
              required
            />
            <SelectConnectedField
              label={text.labels.billingCountry}
              name="address_country"
              options={[
                { value: ``, label: text.labels.selectCountry },
                ...COUNTRY_CODES.map(country => ({
                  value: country.code,
                  label: country.name,
                })),
              ]}
              css={theme => ({
                paddingTop: theme.space[4],
                width: `100%`,
                [theme.mediaQueries.tablet]: {
                  width: `25%`,
                },
              })}
              required
            />
          </ContentBlock>

          <Heading>{text.optionalInformation}</Heading>
          <ContentBlock className="fs-block">
            <InputConnectedField
              label={text.formEmail}
              name="email"
              placeholder="Email"
              css={theme => ({
                paddingTop: theme.space[4],
                width: `100%`,
                [theme.mediaQueries.tablet]: {
                  paddingRight: theme.space[7],

                  width: `50%`,
                },
              })}
            />
            <InputConnectedField
              label={text.company}
              name="company"
              placeholder="Company"
              css={theme => ({
                paddingTop: theme.space[4],
                width: `100%`,
                [theme.mediaQueries.tablet]: {
                  width: `50%`,
                },
              })}
            />
          </ContentBlock>
          {children}
          <Actions>
            <Button
              variant={`SECONDARY`}
              tone={`NEUTRAL`}
              onClick={() => cancelCallback()}
              type={`reset`}
            >
              {cancelButtonText}
            </Button>
            <Button
              variant={`PRIMARY`}
              type="submit"
              loading={disabled}
              data-cy="start-subscription-button"
              rightIcon={<MdArrowForward />}
            >
              {submitButtonText}
            </Button>
          </Actions>
        </Form.FormElement>
      )}
    </Form>
  )
}

CheckoutForm.propTypes = {
  planId: PropTypes.string.isRequired,
  organizationId: PropTypes.string.isRequired,
  mutate: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
}

export default CheckoutForm
