import * as React from "react"
import { ThemeCss } from "gatsby-interface"
import { userSettings as text } from "@modules/locales/default.js"
import Loading from "@modules/ui/components/Loading"
import OrganizationSection from "./Organization"
import ContentSection from "./ContentSection"
import { useUserOrganizationsWithBillingQuery } from "@modules/user-settings/queries.generated"

const orgListCss: ThemeCss = theme => ({
  display: `flex`,
  flexDirection: `column`,
  listStyle: `none`,
  margin: 0,
})

const noOrgsCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[1],
})

const OrganizationsList = () => {
  const { data, loading, error } = useUserOrganizationsWithBillingQuery()

  if (error) {
    return <div css={noOrgsCss}>{text.errorFetchingOrgs}</div>
  }

  const currentOrganizations = (data && data.currentOrganizations) || []

  if (loading) {
    return <Loading delay={1000} bufferSize="none" />
  } else if (currentOrganizations.length === 0) {
    return <div css={noOrgsCss}>{text.noOrgsNote}</div>
  } else {
    return (
      <React.Fragment>
        <p>{text.followTheLink}</p>
        <ul css={orgListCss}>
          {currentOrganizations.map(org => (
            <li key={org.name}>
              <OrganizationSection
                name={org.name}
                id={org.id}
                permissions={org.permissions}
                plan={org.billing?.plan}
              />
            </li>
          ))}
        </ul>
      </React.Fragment>
    )
  }
}

function OrganizationsAndBilling() {
  return (
    <ContentSection>
      <h2>{text.orgsAndBilling}</h2>
      <OrganizationsList />
    </ContentSection>
  )
}

export default OrganizationsAndBilling
