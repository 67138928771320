import * as React from "react"
import { WindowLocation } from "@reach/router"
import { StepIndicator, StepIndicatorStep } from "gatsby-interface"
import { BillingInterval } from "@modules/graphql/types"
import { getPathToOrgDetails } from "@modules/organization/shared/utils"
import { navigate } from "gatsby"
import { SetUpOrganization } from "./SetUpOrganization"
import { PickOrganizationPlan } from "./PickOrganizationPlan"
import { ReviewOrganization } from "./ReviewOrganization"
import { SessionStorageItems } from "@modules/localStorage/constants"
import { WizardHeader } from "@modules/ui/components/WizardHeader"
import { createOrganization as createOrganizationText } from "@modules/locales/default.js"
import { Machine, assign } from "xstate"
import { useMachine } from "@xstate/react"
import { useTracker, SegmentEventType } from "@modules/analytics"

type WizardStateSchema = {
  states: {
    setup: {}
    pickPlan: {}
    review: {}
    success: {}
    cancelled: {}
  }
}

type WizardContext = {
  name: string
  planId: string | null
  planName: string | null
  billingInterval: BillingInterval
}

type WizardEvent =
  | { type: `CHOOSE_NAME`; name: string }
  | {
      type: `PICK_PLAN`
      planInfo: {
        planId: string
        planName: string
        billingInterval: BillingInterval
      }
    }
  | { type: `BACK` }
  | { type: `CHANGE_NAME` }
  | { type: `CHANGE_PLAN` }
  | { type: `CREATED` }
  | { type: `CANCEL` }

export const wizardMachine = Machine<
  WizardContext,
  WizardStateSchema,
  WizardEvent
>(
  {
    id: `createOrganizationWizard`,
    context: {
      name: ``,
      planId: null,
      planName: null,
      billingInterval: BillingInterval.Monthly,
    },
    initial: `setup`,
    states: {
      setup: {
        on: {
          CHOOSE_NAME: {
            target: `pickPlan`,
            actions: [assign({ name: (_, e) => e.name }), `persistContext`],
          },
          CANCEL: {
            target: "cancelled",
          },
        },
      },
      pickPlan: {
        on: {
          BACK: {
            target: `setup`,
          },
          PICK_PLAN: {
            target: `review`,
            actions: [assign((_, e) => e.planInfo), `persistContext`],
          },
        },
      },
      review: {
        on: {
          BACK: {
            target: `pickPlan`,
          },
          CHANGE_NAME: {
            target: `setup`,
          },
          CHANGE_PLAN: {
            target: `pickPlan`,
          },
          CREATED: {
            target: `success`,
          },
        },
      },
      success: {
        type: "final",
        entry: ["clearPersisitedContext"],
      },
      cancelled: {
        type: "final",
        entry: ["clearPersisitedContext"],
      },
    },
  },
  {
    actions: {
      persistContext: context => {
        sessionStorage?.setItem(
          SessionStorageItems.NewSpaceForm,
          JSON.stringify(context)
        )
      },
      clearPersisitedContext: () => {
        sessionStorage?.removeItem(SessionStorageItems.NewSpaceForm)
      },
    },
  }
)

export type CreateOrganizationWizardProps = {
  location?: WindowLocation
}

export function CreateOrganizationWizard({
  location,
}: CreateOrganizationWizardProps) {
  const [current, send] = useMachine(wizardMachine, {
    context: getPersistedState(),
  })
  const { trackSegment, trackAction } = useTracker()

  React.useEffect(() => {
    // We only want to fire "track" event for steps other than the first one,
    // which is already tracked by an automatic Page event
    if (current.value !== `setup`) {
      trackAction({
        eventType: `TRACK_EVENT`,
        name:
          current.value === `pickPlan`
            ? `Viewed Create a Workspace - Pick a Plan`
            : `Viewed Create a Workspace - Review`,
      })
      trackSegment({
        type: SegmentEventType.Track,
        event:
          current.value === `pickPlan`
            ? `Viewed Create a Workspace - Pick a Plan`
            : `Viewed Create a Workspace - Review`,
      })
    }
  }, [current.value])

  return (
    <main>
      <WizardHeader>{createOrganizationText.headers.wizardHeader}</WizardHeader>
      <StepIndicator>
        <StepIndicatorStep
          status={current.value === `setup` ? `ACTIVE` : `DONE`}
        >
          {createOrganizationText.labels.setupStep}
        </StepIndicatorStep>
        <StepIndicatorStep
          status={
            current.value === `pickPlan`
              ? `ACTIVE`
              : current.value === `review`
              ? `DONE`
              : `DEFAULT`
          }
        >
          {createOrganizationText.labels.pickPlanStep}
        </StepIndicatorStep>
        <StepIndicatorStep
          status={current.value === `review` ? `ACTIVE` : `DEFAULT`}
        >
          {createOrganizationText.labels.reviewStep}
        </StepIndicatorStep>
      </StepIndicator>
      {current.value === `setup` && (
        <SetUpOrganization
          initialName={current.context.name}
          onGoBack={() => {
            send({ type: `CANCEL` })
            navigate(`/dashboard/sites`)
          }}
          onGoNext={name => {
            send({ type: `CHOOSE_NAME`, name })
          }}
        />
      )}
      {current.value === `pickPlan` && (
        <PickOrganizationPlan
          selectedPlanId={current.context.planId}
          selectedPlanName={current.context.planName}
          selectedBillingInterval={current.context.billingInterval}
          onGoBack={() => {
            send({ type: `BACK` })
          }}
          onGoNext={planInfo => {
            send({ type: `PICK_PLAN`, planInfo })
          }}
        />
      )}
      {current.value === `review` && (
        <ReviewOrganization
          selectedName={current.context.name}
          selectedPlanId={current.context.planId}
          onEditName={() => {
            send({ type: `CHANGE_NAME` })
          }}
          onChangePlan={() => {
            send({ type: `CHANGE_PLAN` })
          }}
          onGoNext={organizationId => {
            send({ type: `CREATED` })
            navigate(getPathToOrgDetails(organizationId))
          }}
          onGoBack={() => {
            send({ type: `BACK` })
          }}
        />
      )}
    </main>
  )
}

function getPersistedState(): WizardContext {
  const savedStateJSON = sessionStorage?.getItem(
    SessionStorageItems.NewSpaceForm
  )

  if (!savedStateJSON) {
    return wizardMachine.initialState.context
  }

  const savedState: WizardContext = {
    ...wizardMachine.initialState.context,
    ...(JSON.parse(savedStateJSON) || {}),
  }

  return savedState
}
