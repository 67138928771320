import * as React from "react"
import { RouteComponentProps } from "@reach/router"
import { navigate } from "gatsby"
import { ThemeCss } from "gatsby-interface"
import * as qs from "query-string"
import { PageContent, PageHeader } from "@modules/layout/components/Page"
import { upgrade as text } from "@modules/locales/default.js"
import { FormattedMessage } from "@modules/locales"
import {
  trialOptionPlansOrder,
  SupportedPlanTier,
  TRIAL_DAYS,
} from "@modules/billing/constants"
import { useEnableSubscriptionTrialMutation } from "@modules/onboarding/gaFlow/queries.generated"
import { getPathToOrgDetails } from "@modules/organization/shared/utils"
import { BillingInterval, MachinePricingTier } from "@modules/graphql/types"
import {
  UpgradeBackgroundLeft,
  UpgradeBackgroundRight,
} from "../assets/UpgradeBackground"
import { TrialPlanSelector } from "./TrialPlanSelector"
import { useTracker, SegmentEventType } from "@modules/analytics"
import { useTriggerErrorAlert } from "@modules/ui/components/ErrorAlert"
import { useFlags } from "@modules/featureFlags"
import { MultiPlanSelector } from "@modules/billing/shared/components/MultiPlanSelector"
import { PlanSelectionSummary } from "@modules/billing/shared/components/PlanSelectionSummary"
import {
  supportedTiers,
  PlanInfo,
  SupportedPricingTier,
} from "@modules/billing/shared/constants/tiers"

const baseCss: ThemeCss = theme => ({
  width: `100%`,
  margin: `${theme.space[15]} 0`,
})

const pageHeaderHeadingCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[7],
  maxWidth: `40rem`,
  [theme.mediaQueries.desktop]: {
    fontSize: theme.fontSizes[9],
  },
})

const pageHeaderLedeCss: ThemeCss = theme => ({
  maxWidth: `30rem`,
  fontSize: theme.fontSizes[2],
  fontFamily: theme.fonts.body,
  color: theme.colors.grey[90],
})

const pageHeaderHeadingEmphasisCss: ThemeCss = theme => ({
  color: theme.colors.purple[60],
})

type TrialPlanUpsellProps = RouteComponentProps & {
  organizationId: string
}

export function TrialPlanUpsell({
  organizationId,
  location,
}: TrialPlanUpsellProps) {
  const { flags, ready } = useFlags()
  const [planInfo, setPlanInfo] = React.useState<PlanInfo>({
    planId: null,
    planName: null,
    billingInterval: BillingInterval.Monthly,
    buildsTier: MachinePricingTier.Standard,
    hostingTier: MachinePricingTier.Standard,
  })
  const { trackSegment, trackAction } = useTracker()
  const [mutate] = useEnableSubscriptionTrialMutation()
  const [setError, errorAlert] = useTriggerErrorAlert()

  const { loginRedirectUrl } = qs.parse(location?.search || "")

  const nextPath =
    loginRedirectUrl && typeof loginRedirectUrl === "string"
      ? loginRedirectUrl
      : getPathToOrgDetails(organizationId)

  const moveToApp = () => {
    if (
      planInfo.buildsTier === MachinePricingTier.Free &&
      planInfo.hostingTier === MachinePricingTier.Free
    ) {
      trackAction({
        eventType: `TRACK_EVENT`,
        name: `Plan selected`,
        valueString: `Free`,
        uiSource: `Upgrade Page`,
      })
      trackSegment({
        type: SegmentEventType.Track,
        event: "Plan Selected",
        properties: {
          location: "Upgrade Page",
          plan: "Free",
        },
      })
      navigate(nextPath)
    } else {
      const selectedBuildsName =
        supportedTiers[planInfo.buildsTier as SupportedPricingTier].name
      const selectedHostingName =
        supportedTiers[planInfo.hostingTier as SupportedPricingTier].name
      const planName =
        selectedBuildsName && selectedHostingName
          ? `${selectedBuildsName} Builds - ${selectedHostingName} Hosting`
          : null

      if (planName) {
        trackAction({
          eventType: `TRACK_EVENT`,
          name: `Plan selected`,
          valueString: `${planName} (trial)`,
          uiSource: `Upgrade Page`,
        })
        trackSegment({
          type: SegmentEventType.Track,
          event: "Plan Selected",
          properties: {
            location: "Upgrade Page",
            plan: `${planName} (trial)`,
          },
        })

        return mutate({
          variables: {
            organizationId,
            planName,
          },
        })
          .then(() => {
            navigate(nextPath)
          })
          .catch(err => {
            setError(err)
          })
      } else {
        navigate(nextPath)
      }
    }
  }

  const enableTrial = (planName: SupportedPlanTier) => {
    return mutate({
      variables: {
        organizationId,
        planName,
      },
    })
      .then(() => {
        navigate(nextPath)
      })
      .catch(err => {
        setError(err)
      })
  }

  // if flag context exist
  if (!ready) {
    return null
  }

  return (
    <main css={baseCss}>
      <UpgradeBackgroundLeft
        css={{ position: `absolute`, left: 0, top: `18rem`, zIndex: -1 }}
      />
      <UpgradeBackgroundRight
        css={{ position: `absolute`, right: 0, zIndex: -1 }}
      />
      <PageHeader variant="CENTER" css={{ paddingTop: 0 }}>
        <PageHeader.Heading css={pageHeaderHeadingCss}>
          <FormattedMessage<"trialDays", "strong">
            message={text.headers.upgradeToFreeTrial}
            values={{
              trialDays: TRIAL_DAYS,
            }}
            tags={{
              strong: function(content) {
                return <span css={pageHeaderHeadingEmphasisCss}>{content}</span>
              },
            }}
          />
        </PageHeader.Heading>
        <PageHeader.Lede css={pageHeaderLedeCss}>
          <p>
            <FormattedMessage<"trialDays", "strong">
              message={text.headers.upgradeDetails}
              values={{
                trialDays: TRIAL_DAYS,
              }}
              tags={{
                strong: function(content) {
                  return <strong>{content}</strong>
                },
              }}
            />
          </p>
        </PageHeader.Lede>
      </PageHeader>
      <PageContent.Positioner>
        <div
          css={{
            maxWidth: `56rem`,
            margin: `0 auto`,
          }}
        >
          {flags.multiTiersPlans ? (
            <React.Fragment>
              <MultiPlanSelector
                planInfo={planInfo}
                setPlanInfo={setPlanInfo}
                diyTiersOnly={true}
                showIntervalToggle={false}
              />
              <PlanSelectionSummary
                planInfo={planInfo}
                onNext={moveToApp}
                showPrices={false}
              />
            </React.Fragment>
          ) : (
            <TrialPlanSelector
              planOptions={trialOptionPlansOrder}
              onSelectPlan={tier => {
                if (tier === SupportedPlanTier.Free) {
                  trackSegment({
                    type: SegmentEventType.Track,
                    event: "Plan Selected",
                    properties: {
                      location: "Upgrade Page",
                      plan: "Free",
                    },
                  })
                  return navigate(nextPath)
                } else if (tier === SupportedPlanTier.Individual) {
                  trackAction({
                    eventType: `TRACK_EVENT`,
                    name: `Plan selected`,
                    valueString: `Individual (trial)`,
                    uiSource: `Upgrade Page`,
                  })
                  trackSegment({
                    type: SegmentEventType.Track,
                    event: "Plan Selected",
                    properties: {
                      location: "Upgrade Page",
                      plan: "Individual (trial)",
                    },
                  })
                  enableTrial(SupportedPlanTier.Individual)
                } else if (tier === SupportedPlanTier.Team) {
                  trackAction({
                    eventType: `TRACK_EVENT`,
                    name: `Plan selected`,
                    valueString: `Team (trial)`,
                    uiSource: `Upgrade Page`,
                  })
                  trackSegment({
                    type: SegmentEventType.Track,
                    event: "Plan Selected",
                    properties: {
                      location: "Upgrade Page",
                      plan: "Team (trial)",
                    },
                  })
                  enableTrial(SupportedPlanTier.Team)
                }
              }}
              getPlanHint={tier => {
                if (
                  tier === SupportedPlanTier.Individual ||
                  tier === SupportedPlanTier.Team
                ) {
                  return (
                    <FormattedMessage<never, "strong">
                      message={text.messages.automaticDowngrade}
                      tags={{
                        strong: function(content) {
                          return <strong>{content}</strong>
                        },
                      }}
                    />
                  )
                }
              }}
              getPlanButtonLabel={tier => {
                if (tier === SupportedPlanTier.Free) {
                  return text.actions.pickTier1Trial
                } else if (tier === SupportedPlanTier.Individual) {
                  return text.actions.pickTier2Trial
                } else if (tier === SupportedPlanTier.Team) {
                  return text.actions.pickTier3Trial
                }
                return "Get Started"
              }}
              getPlanButtonStyle={tier => {
                if (tier === SupportedPlanTier.Free) {
                  return `SECONDARY`
                } else if (tier === SupportedPlanTier.Individual) {
                  return `PRIMARY`
                } else if (tier === SupportedPlanTier.Team) {
                  return `PRIMARY`
                }
                return `PRIMARY`
              }}
            />
          )}
          {errorAlert}
        </div>
      </PageContent.Positioner>
    </main>
  )
}
