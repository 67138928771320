import * as React from "react"
import { WindowLocation } from "@reach/router"
import Loading from "@modules/ui/components/Loading"
import {
  EmptyState,
  Link,
  Spacer,
  ThemeCss,
  Heading,
  Badge,
  Tooltip,
} from "gatsby-interface"
import { Feed, FeedItem } from "@modules/a11y/components/Feed"
import { BuildCard } from "@modules/build/card/components/BuildCard"
import { MdArrowBack, MdSettings } from "react-icons/md"
import {
  getPathToBuildDetails,
  getPathToSiteDetails,
} from "@modules/site/details/utils"
import {
  PageWithTabsContentSection,
  StandardSingleColumn,
} from "@modules/ui/layouts/Containers"
import { Waypoint } from "react-waypoint"
import { FormattedMessage } from "@modules/locales"
import { buildsByBranchView as buildsByBranchViewText } from "@modules/locales/default.js"
import { useBuilds } from "../hooks/useDeploys"
import { useLatestBuildByStatus } from "@modules/build/shared/hooks/useLatestBuildByStatus"
import { BuildStatus, CdnVendor } from "@modules/graphql/types"
import { useSiteDetailsQuery } from "@modules/site/shared/queries.generated"
import { useOrganizationBaseDetailsQuery } from "@modules/organization/queries.generated"
import { LastBuildInfo } from "@modules/site/buildsView/components/LastBuildInfo"
import SiteBranch from "@modules/site/shared/components/SiteBranch"
import BuildPublishedDate from "@modules/build/shared/components/BuildPublishedDate"
import TriggerBuild from "@modules/build/shared/components/TriggerBuild"
import {
  deploysView as deploysViewText,
  ui as uiText,
} from "@modules/locales/default.js"
import { visuallyHiddenCss } from "@modules/a11y/stylesheets"
import { ExternalLink } from "@modules/ui/components/ExternalLink"
import { getSiteDetailsTabBasePath } from "@modules/site/details/utils"
import { SiteDetailsTab } from "@modules/site/details/constants"
import { useSiteChangedSubscription } from "@modules/site/shared/queries.generated"
import { useLocalSiteDetailsForBuilds } from "@modules/site/shared/hooks/useLocalSiteDetails"
import { PublishBuildConfirmation } from "@modules/build/shared/components/PublishBuildConfirmation"
import { GatsbyCloudLogo } from "@modules/brand/assets/GatsbyCloudLogo"
import { isEligiblePlanTierFor } from "@modules/organization/shared/utils/isEligiblePlanTierFor"
import {
  useTriggerErrorAlert,
  ErrorAlert,
} from "@modules/ui/components/ErrorAlert"
/**
 * When modifying this file, make sure you modify the PullRequestBuildsPage accordingly
 * to have the same UI for the builds by branches and builds by pull requests
 */
export type BuildsListPageProps = {
  organizationId: string
  siteId: string
  location?: WindowLocation
  branch: string
}

export function BuildsListPage({
  branch: rawBranch,
  siteId,
  organizationId,
}: BuildsListPageProps) {
  const [setError, errorAlert] = useTriggerErrorAlert()
  const [
    triggerBuildError,
    setTriggerBuildError,
  ] = React.useState<JSX.Element | null>(null)

  const {
    data: siteDetailsData,
    error: errorSiteDetails,
    refetch: refetchSiteDetials,
  } = useSiteDetailsQuery({
    variables: { id: siteId },
    fetchPolicy: "cache-and-network",
  })

  useSiteChangedSubscription({
    variables: { id: siteId },
  })

  const siteDetails = siteDetailsData?.siteDetails

  const siteDetailsFromCache = useLocalSiteDetailsForBuilds(siteId)
  const latestHostingDeployVersion =
    siteDetailsFromCache?.latestHostingDeployVersion
  const manualHostingDeploysEnabled =
    siteDetailsFromCache?.manualHostingDeploysEnabled

  const [buildToPublish, setBuildToPublish] = React.useState<string | null>(
    null
  )

  const linkToSiteSettings = getSiteDetailsTabBasePath(
    SiteDetailsTab.Settings,
    siteId,
    organizationId
  )

  // In the event that a branch name contains a `/`, the
  // routing is disturbed unless encoded (and decoded).
  const branch = decodeURIComponent(rawBranch)

  const repositoryUrl = siteDetails?.repository?.url

  const [loadingLastBuild, lastBuild] = useLatestBuildByStatus({
    siteId,
    siteBranch: branch,
    status: BuildStatus.Success,
    setError: setError,
  })

  const [
    builds,
    cdnVendor,
    { loading, error, loadingMore, loadMore },
  ] = useBuilds(siteId, branch)

  const gatsbyHostingOn = cdnVendor === CdnVendor.CloudCdn

  const { data: orgData } = useOrganizationBaseDetailsQuery({
    variables: { id: organizationId },
  })

  const organization = orgData?.organizationDetails
  const hostingTier = organization?.billing?.plan?.hostingTier
  const isEligiblePlan = isEligiblePlanTierFor(hostingTier)

  if (loading || loadingLastBuild) {
    return (
      <PageWithTabsContentSection>
        <StandardSingleColumn>
          <Loading message={buildsByBranchViewText.messages.loadingBuilds} />
        </StandardSingleColumn>
      </PageWithTabsContentSection>
    )
  }

  const actualError = error || errorSiteDetails
  if (actualError) {
    return (
      <PageWithTabsContentSection>
        <StandardSingleColumn>
          <ErrorAlert data-testid="builds-list-page-error">
            <FormattedMessage<"error">
              message={buildsByBranchViewText.messages.errorLoadingBuilds}
              values={{ error: actualError?.message }}
            />
          </ErrorAlert>
        </StandardSingleColumn>
      </PageWithTabsContentSection>
    )
  }

  if (builds.length === 0) {
    return (
      <PageWithTabsContentSection>
        <StandardSingleColumn>
          <EmptyState
            variant="BORDERED"
            heading={buildsByBranchViewText.headers.noBuilds}
            text={buildsByBranchViewText.messages.noBuildsForBranch}
          />
        </StandardSingleColumn>
      </PageWithTabsContentSection>
    )
  }

  const backLink = getPathToSiteDetails(siteId, organizationId)

  return (
    <main>
      <PageWithTabsContentSection>
        <StandardSingleColumn id="builds">
          <Link variant="SIMPLE" to={backLink} css={linkCss}>
            <MdArrowBack />
            {buildsByBranchViewText.labels.backToSiteDetails}
          </Link>
          <Spacer size={7} />
          <div>
            <div css={topCss}>
              <div css={infosCss}>
                <Heading as="h1" fontVariant="UI" css={headingCss}>
                  <FormattedMessage<"branch", "strong">
                    message={buildsByBranchViewText.headers.listOfBuilds}
                    values={{ branch }}
                    tags={{
                      strong: function(content) {
                        return <strong>{content}</strong>
                      },
                    }}
                  />
                </Heading>

                {siteDetails?.stableBuildURL ? (
                  <ExternalLink
                    href={siteDetails?.stableBuildURL}
                    truncate
                    size={2}
                  />
                ) : (
                  <LastBuildInfo buildId={lastBuild?.id} />
                )}

                <div css={rowCss}>
                  <SiteBranch>
                    <strong>{branch}</strong>
                  </SiteBranch>

                  <Spacer size={5} direction="horizontal" />

                  {lastBuild?.endedAt && (
                    <React.Fragment>
                      <BuildPublishedDate
                        buildPublishedDate={lastBuild.endedAt}
                      />
                      <Spacer size={5} direction="horizontal" />
                    </React.Fragment>
                  )}

                  {gatsbyHostingOn && isEligiblePlan && (
                    <React.Fragment>
                      <Spacer size={4} direction="horizontal" />
                      <span css={deploysCss}>
                        <GatsbyCloudLogo />{" "}
                        {deploysViewText.labels.automaticDeploys}:
                        <Spacer size={3} direction="horizontal" />
                        <Badge
                          tone={
                            siteDetails?.manualHostingDeploysEnabled
                              ? `BRAND`
                              : `SUCCESS`
                          }
                        >
                          {siteDetails?.manualHostingDeploysEnabled
                            ? uiText.labels.disabled
                            : uiText.labels.enabled}
                        </Badge>
                        <Tooltip label={uiText.actions.editSettings}>
                          <span css={settingsLinkCss}>
                            <Link
                              to={`${linkToSiteSettings}/builds`}
                              variant="SIMPLE"
                            >
                              <MdSettings />
                            </Link>
                          </span>
                        </Tooltip>
                      </span>
                    </React.Fragment>
                  )}
                </div>
              </div>

              {organization && (
                <div css={triggerBuildCss}>
                  <TriggerBuild
                    siteId={siteId}
                    selectedBranch={branch}
                    orgStatus={organization?.status}
                    size={`M`}
                    triggerBuildError={triggerBuildError}
                    setTriggerBuildError={setTriggerBuildError}
                  />
                </div>
              )}
            </div>
            {/* TODO: refactor the way that both types of error use the same shared errorAlert */}
            {errorAlert}
            {triggerBuildError}
          </div>

          <Spacer size={5} />

          <h2 css={visuallyHiddenCss}>
            <FormattedMessage<"productionBranch">
              message={deploysViewText.headers.allBuildsOfBranch}
              values={{
                productionBranch: branch,
              }}
            />
          </h2>
          <Feed loading={loadingMore} labelledBy="builds" count={builds.length}>
            <div>
              {builds.map((build, index) => (
                <div key={`build-${build.id}`}>
                  <FeedItem
                    labelledBy={`build-${build.id}`}
                    describedBy={`deploy-status-${build.id}`}
                    position={index + 1}
                    id={build.id}
                  >
                    <BuildCard
                      as="div"
                      id={`build-${build.id}`}
                      siteId={siteId}
                      organizationId={organizationId}
                      pullRequestId={build?.pullRequest?.id}
                      title={build.commit?.message || build.branch || ``}
                      buildId={build.id}
                      status={build.buildStatus}
                      createdAt={build.createdAt}
                      startedAt={build.startedAt}
                      latestHostingDeployVersion={
                        latestHostingDeployVersion ?? undefined
                      }
                      manualHostingDeploysEnabled={manualHostingDeploysEnabled}
                      onPublish={setBuildToPublish}
                      gatsbyHostingOn={gatsbyHostingOn}
                      isEligiblePlan={isEligiblePlan}
                      /* backend allows for null. Fallbacking to undefined for safe types in child components */
                      duration={build.duration || undefined}
                      endedAt={build.endedAt}
                      branch={build.branch || ``}
                      /* backend allows for null. Fallbacking to undefined for safe types in child components */
                      commit={build.commit || undefined}
                      /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
                      runnerType={build.runnerType!}
                      /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
                      buildType={build.buildType!}
                      /* backend allows for null. Fallbacking to undefined for safe types in child components */
                      source={build.source || undefined}
                      /* backend allows for null. Fallbacking to undefined for safe types in child components */
                      author={build.author || undefined}
                      isProductionBranch={branch === build.branch}
                      viewDetailsHref={getPathToBuildDetails(
                        build.id,
                        siteId,
                        organizationId
                      )}
                      deployStartedAt={build?.deployStartedAt}
                      deployEndedAt={build?.deployEndedAt}
                      onBuildSucceed={refetchSiteDetials}
                      repositoryUrl={repositoryUrl}
                      routeMetadata={build?.routeMetadata || undefined}
                    />
                  </FeedItem>
                  <Spacer size={5} />
                </div>
              ))}
            </div>
          </Feed>
          {loadingMore && builds.length !== 0 && (
            <div css={{ textAlign: "center" }}>
              <Loading
                variant="baby"
                message={buildsByBranchViewText.messages.loadingBuilds}
              />
            </div>
          )}
          <Waypoint onEnter={loadMore} />
        </StandardSingleColumn>
      </PageWithTabsContentSection>

      {!!buildToPublish && (
        <PublishBuildConfirmation
          siteId={siteId}
          buildId={buildToPublish}
          onDismiss={() => setBuildToPublish(null)}
          onComplete={() => {
            setBuildToPublish(null)
          }}
          manualHostingDeploysEnabled={manualHostingDeploysEnabled ?? undefined}
        />
      )}
    </main>
  )
}

/* styles */

const topCss: ThemeCss = theme => ({
  alignItems: `end`,
  display: `grid`,
  gap: theme.space[7],
  marginBottom: theme.space[6],

  [theme.mediaQueries.desktop]: {
    marginBottom: theme.space[8],
    gridTemplateColumns: `1fr auto`,
  },
})

const headingCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[4],
  color: theme.colors.grey[80],
  fontWeight: theme.fontWeights.body,

  [theme.mediaQueries.desktop]: {
    fontSize: theme.fontSizes[5],
  },
})

const infosCss: ThemeCss = theme => ({
  display: `grid`,
  gap: theme.space[4],
  gridTemplateColumns: `minmax(0, 1fr)`,
})

const rowCss: ThemeCss = theme => ({
  display: `grid`,

  "& > span": {
    minHeight: theme.space[7],
  },

  [theme.mediaQueries.desktop]: {
    display: `flex`,
    alignItems: `center`,
    flexDirection: `row`,
  },
})

const linkCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[0],
})

const deploysCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[1],
  color: theme.colors.grey[60],
  display: `inline-flex`,
  alignItems: `center`,

  svg: {
    width: `1.25em`,
    height: `1.25em`,
    marginRight: theme.space[3],
  },
})

const settingsLinkCss: ThemeCss = theme => ({
  display: `flex`,
  alignItems: `center`,

  svg: {
    fontSize: `.8em`,
    marginLeft: theme.space[2],
    color: theme.colors.grey[40],
  },

  "&:hover svg": {
    color: theme.colors.purple[40],
  },
})

const triggerBuildCss: ThemeCss = theme => ({
  marginTop: theme.space[3],
  marginLeft: "auto",

  [theme.mediaQueries.tablet]: {
    marginTop: 0,
    marginLeft: 0,
    marginBottom: theme.space[3],
  },
})
