import * as React from "react"
import * as qs from "query-string"
import { navigate } from "gatsby"
import { RouteComponentProps, WindowLocation } from "@reach/router"
import { LoginScreen } from "@modules/login/cloud/components/LoginScreen"
import { processLoginAttempt, isAuthenticated } from "@modules/auth"
import { AnonymousFeatureFlagProvider } from "@modules/featureFlags"
import { useTracker, SegmentEventType } from "@modules/analytics"

export function LoginPage({ location }: RouteComponentProps) {
  useTrackAndNavigateAuth(location)

  return (
    <AnonymousFeatureFlagProvider>
      <LoginScreen
        location={location}
        actionType="login"
        onLoginOptionClick={loginOption => {
          processLoginAttempt(location?.search || "", loginOption)
        }}
      />
    </AnonymousFeatureFlagProvider>
  )
}

function useTrackAndNavigateAuth(location?: WindowLocation) {
  const { trackPageViewed, trackSegment, trackAction } = useTracker()

  React.useEffect(() => {
    const query = qs.parse(location?.search || "") || {}

    const { invitationId, invitationToken } = query

    if (invitationId) {
      trackPageViewed(`Invitation Log In Page`)
    } else {
      trackPageViewed(`Log In Page`)
    }

    if (!isAuthenticated() && invitationId) {
      trackAction({
        eventType: `TRACK_EVENT`,
        name: `Invitation to Gatsby Cloud accepted, unauthenticated user`,
      })
      trackSegment({
        type: SegmentEventType.Track,
        event: `Invitation to Gatsby Cloud accepted, unauthenticated user`,
        properties: {
          invitationId: invitationId as string,
        },
      })
    }

    if (isAuthenticated()) {
      if (invitationId && invitationToken) {
        navigate(`/dashboard/invitations/${invitationId}`)
        return () => {
          return undefined
        }
      }

      navigate(`/dashboard/sites`)

      return () => {
        return undefined
      }
    }
  }, [])
}
