import * as React from "react"
import ContentSection from "./ContentSection"
import { userSettings as text } from "@modules/locales/default.js"

function OrganizationsAndBilling() {
  return (
    <ContentSection>
      <h1>{text.yourAccount}</h1>
    </ContentSection>
  )
}

export default OrganizationsAndBilling
