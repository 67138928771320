import * as React from "react"
import {
  ThemeCss,
  Link,
  Button,
  Card,
  Theme,
  Spacer,
  Badge,
} from "gatsby-interface"
import {
  BuildStatus,
  BuildCommit,
  BuildRunnerType,
  BuildType,
  User,
  BuildSource,
  BuildRouteMetadata,
} from "@modules/graphql/types"

import { visuallyHiddenCss } from "@modules/a11y/stylesheets"
import BuildStatusIndicator from "@modules/build/shared/components/BuildStatusIndicator"
import { BuildEventText } from "@modules/build/shared/components/BuildEventText"
import BuildAuthor from "./BuildAuthor"
import BuildCardStatusInfo from "./BuildCardStatusInfo"
import useBuildChangedSubscription from "@modules/build/shared/hooks/useBuildChangedSubscription"
import { usePullRequestChangedSubscription } from "@modules/build/shared/hooks/usePullRequestChangedSubscription"
import { DeploymentStatus } from "./DeploymentStatus"
import { DeploymentTiming } from "./DeploymentTiming"
import { isIncrementalUpdate } from "@modules/build/shared/utils"
import IncrementalBuildChip from "@modules/build/shared/components/IncrementalBuildChip"
import {
  deploysView as deploysViewText,
  build as buildText,
} from "@modules/locales/default.js"
import { getContextuallyFormatedDate } from "@modules/ui/utils/getContextuallyFormatedDate"
import { LighthouseScores } from "@modules/metrics/components/LighthouseScores"
import BuildCommitInfo from "@modules/build/shared/components/BuildCommitInfo"
import { GatsbyCloudLogo } from "@modules/brand/assets/GatsbyCloudLogo"
import { useFlags } from "@modules/featureFlags"
import { AlphaIcon } from "../../shared/components/AlphaIcon"
import { InfinityIcon } from "../../shared/components/InfinityIcon"

import {
  rowCss,
  itemCss,
  actionsCss,
  separatorCss,
} from "@modules/build/card/stylesheets"

export type BuildCardProps = {
  id: string
  siteId: string
  organizationId: string
  buildId: string
  status: BuildStatus
  branch: string
  runnerType: BuildRunnerType
  buildType: BuildType
  isProductionBranch: boolean
  viewDetailsHref: string
  viewAllBuildsHref?: string
  repositoryUrl?: string
  createdAt: string
  title?: string
  pullRequestId?: string
  commit?: BuildCommit
  author?: Partial<User>
  source?: BuildSource
  duration?: number
  startedAt?: string // coming as ISOstring
  endedAt?: string // coming as ISOstring
  deployStartedAt?: string
  deployEndedAt?: string
  onBuildSucceed?: () => void
  onPublish?: (id: string) => void
  latestHostingDeployVersion?: string
  manualHostingDeploysEnabled?: boolean
  gatsbyHostingOn?: boolean
  isEligiblePlan?: boolean
  dataTestid?: string
  as?: `div` | `article`
  routeMetadata?: BuildRouteMetadata
}

export function BuildCard({
  id,
  siteId,
  organizationId,
  buildId,
  pullRequestId,
  status,
  startedAt,
  createdAt,
  duration,
  endedAt,
  branch,
  commit,
  runnerType,
  buildType,
  author,
  source,
  viewDetailsHref,
  viewAllBuildsHref,
  deployStartedAt,
  deployEndedAt,
  onBuildSucceed,
  repositoryUrl,
  latestHostingDeployVersion,
  manualHostingDeploysEnabled,
  onPublish,
  gatsbyHostingOn,
  isEligiblePlan,
  dataTestid,
  as,
  routeMetadata,
}: BuildCardProps) {
  const { flags } = useFlags()
  const creationDate = getContextuallyFormatedDate(createdAt)

  const hasDsgRoutes = routeMetadata && routeMetadata.dsgRouteCount > 0
  const hasSsrRoutes = routeMetadata && routeMetadata.ssrRouteCount > 0
  const hasFunctionRoutes =
    routeMetadata && routeMetadata.functionRouteCount > 0

  useBuildChangedSubscription(buildId, runnerType, onBuildSucceed)
  usePullRequestChangedSubscription(pullRequestId)

  const isPublished = latestHostingDeployVersion === buildId

  const showDeployment =
    !gatsbyHostingOn || !isEligiblePlan
      ? true
      : isPublished ||
        (status === BuildStatus.Publishing && !manualHostingDeploysEnabled) ||
        status === BuildStatus.PublishCanceled ||
        status === BuildStatus.PublishError
      ? true
      : false

  const isSuccessfullyBuilt = [
    BuildStatus.Success,
    BuildStatus.PublishCanceled,
    BuildStatus.PublishError,
  ].includes(status)

  return (
    <Card
      as={as}
      data-cy={buildId}
      data-testid={dataTestid}
      css={(theme: Theme) => [
        cardCss(theme),
        gatsbyHostingOn && isEligiblePlan && isPublished && publishedCss(theme),
      ]}
    >
      <div css={cardHeaderCss}>
        <h3 css={titleCss}>
          <Link to={viewDetailsHref} variant="SIMPLE" css={titleLinkCss}>
            <BuildEventText
              commit={commit}
              buildType={buildType}
              buildSource={source}
              runnerType={runnerType}
            />
          </Link>
        </h3>

        {viewAllBuildsHref && (
          <Link variant="SIMPLE" to={viewAllBuildsHref} css={headerLinkCss}>
            {deploysViewText.labels.history}
          </Link>
        )}

        {!flags.gatsbyv4 && !viewAllBuildsHref && isSuccessfullyBuilt && (
          <Link
            to={`${viewDetailsHref}#functions`}
            variant="SIMPLE"
            css={headerLinkCss}
          >
            {deploysViewText.actions.viewFunctions}
          </Link>
        )}

        {flags.gatsbyv4 && isSuccessfullyBuilt && (
          <Link
            href={`https://build-${buildId}${process.env.GATSBY_PREVIEW_DOMAIN}`}
            variant="SIMPLE"
            css={headerLinkCss}
          >
            {deploysViewText.actions.visitBuild}
          </Link>
        )}
      </div>

      <div css={rowsCss}>
        <div css={rowCss}>
          <div css={itemCss} data-cy="deploy-build-status">
            <BuildStatusIndicator
              id={`deploy-status-${buildId}`}
              a11yId={buildId}
              buildStatus={status}
              runnerType={runnerType}
              branch={branch}
            />
            <BuildCardStatusInfo
              createdAt={createdAt}
              buildStatus={status}
              startedAt={startedAt}
              endedAt={endedAt}
              duration={duration}
            />
          </div>

          {deployStartedAt && showDeployment && (
            <React.Fragment>
              <span css={separatorCss}>›</span>
              <div css={itemCss}>
                <DeploymentStatus value={status} />

                <DeploymentTiming
                  status={status}
                  startDate={new Date(deployStartedAt)}
                  endDate={deployEndedAt ? new Date(deployEndedAt) : undefined}
                  vendor={""}
                />
              </div>
            </React.Fragment>
          )}

          {gatsbyHostingOn &&
            isEligiblePlan &&
            status === BuildStatus.Success &&
            isPublished && (
              <React.Fragment>
                <span css={separatorCss}>›</span>

                <div css={itemCss}>
                  <GatsbyCloudLogo
                    css={{
                      width: `1.5em`,
                      height: `auto`,
                    }}
                  />{" "}
                  <span
                    css={(theme: Theme) => ({
                      fontWeight: theme.fontWeights.bold,
                    })}
                  >
                    {buildText.labels.published}
                  </span>
                </div>
              </React.Fragment>
            )}

          {flags.gatsbyv4 && isSuccessfullyBuilt && (
            <div css={badgesCss}>
              {hasDsgRoutes && (
                <Badge tone="NEUTRAL" textVariant="DEFAULT">
                  {buildText.labels.dsg}
                </Badge>
              )}

              {hasSsrRoutes && (
                <Badge tone="NEUTRAL" textVariant="DEFAULT" Icon={InfinityIcon}>
                  {buildText.labels.ssr}
                </Badge>
              )}

              {hasFunctionRoutes && (
                <Badge tone="NEUTRAL" textVariant="DEFAULT" Icon={AlphaIcon}>
                  {buildText.labels.func}
                </Badge>
              )}
            </div>
          )}

          <div css={actionsCss}>
            {status === BuildStatus.Error && (
              <Link
                to={`${viewDetailsHref}#errors`}
                variant="SIMPLE"
                css={errorCss}
              >
                {deploysViewText.actions.viewErrors}
              </Link>
            )}

            {gatsbyHostingOn &&
              isEligiblePlan &&
              isSuccessfullyBuilt &&
              !isPublished && (
                <React.Fragment>
                  <Button
                    size="M"
                    variant="SECONDARY"
                    onClick={onPublish ? () => onPublish(buildId) : undefined}
                    rightIcon={
                      <GatsbyCloudLogo
                        css={_ => ({
                          width: `1.5em`,
                          height: `auto`,
                        })}
                      />
                    }
                  >
                    {buildText.actions.publish}
                  </Button>
                  {!flags.gatsbyv4 && (
                    <Spacer size={7} direction="horizontal" />
                  )}
                </React.Fragment>
              )}

            {!flags.gatsbyv4 && isSuccessfullyBuilt && (
              <Link
                href={`https://build-${buildId}${process.env.GATSBY_PREVIEW_DOMAIN}`}
                variant="SIMPLE"
              >
                {deploysViewText.actions.viewBuild}
              </Link>
            )}
          </div>
        </div>

        <div css={rowCss}>
          <BuildAuthor
            buildType={buildType}
            commit={commit}
            author={author ? author : undefined}
            source={source}
          />

          <div css={itemCss}>
            <span css={visuallyHiddenCss}>
              {deploysViewText.messages.buildTriggered}
            </span>
            <span>{creationDate}</span>
          </div>

          <BuildCommitInfo
            branch={branch}
            commit={commit}
            repositoryUrl={repositoryUrl || ""}
            withLink={true}
            withIcon={true}
          />

          {isIncrementalUpdate(runnerType, buildType) && (
            <IncrementalBuildChip />
          )}
        </div>

        {[
          BuildStatus.Success,
          BuildStatus.Publishing,
          BuildStatus.PublishCanceled,
          BuildStatus.PublishError,
        ].includes(status) && (
          <div css={rowCss}>
            <LighthouseScores
              siteId={siteId}
              organizationId={organizationId}
              buildId={buildId}
              branch={branch}
            />
          </div>
        )}
      </div>
    </Card>
  )
}

/* styles */

const cardCss: ThemeCss = theme => ({
  padding: `${theme.space[6]} ${theme.space[6]} ${theme.space[4]}`,

  [theme.mediaQueries.tablet]: {
    padding: `${theme.space[7]} ${theme.space[7]} ${theme.space[5]}`,
  },
})

const cardHeaderCss: ThemeCss = theme => ({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",

  [theme.mediaQueries.tablet]: {
    flexDirection: "row",
    alignItems: "center",
  },
})

const titleCss: ThemeCss = theme => ({
  margin: 0,
  fontSize: theme.fontSizes[2],
  fontWeight: theme.fontWeights.semiBold,
  fontFamily: theme.fonts.body,
  display: `flex`,
  justifyContent: `flex-start`,
  marginRight: `auto`,
})

const titleLinkCss: ThemeCss = theme => ({
  display: `block`,
  overflow: `hidden`,
  textOverflow: `ellipsis`,
})

const headerLinkCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[1],
  marginLeft: theme.space[5],
})

const publishedCss: ThemeCss = theme => ({
  borderLeft: `4px solid ${theme.colors.purple[50]}`,
  paddingLeft: `calc(${theme.space[7]} - 4px)`,
})

const rowsCss: ThemeCss = theme => ({
  marginTop: theme.space[4],
})

const errorCss: ThemeCss = theme => ({
  color: theme.colors.red[70],

  "&:hover": {
    color: theme.colors.red[40],
  },
})

const badgesCss: ThemeCss = theme => ({
  display: `flex`,

  "& > *": {
    marginRight: theme.space[3],
  },
})
