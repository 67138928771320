import React from "react"
import { Router, Redirect } from "@reach/router"
import { Billing } from "./features/Billing"
import { useFlags } from "@modules/featureFlags"
import {
  ProtectedRoute,
  ProtectedOrganizationRoute,
  AuthRedirect,
  Logout,
  AuthClose,
} from "./features/Auth"
import {
  SiteCreationTypePicker,
  ImportSiteWizard,
  ProvisionSiteWizard,
  DeployNowWizard,
} from "./features/CreateSite"
import { LeadCapture, TrialPlanUpsell } from "./features/Onboarding"
import { BuildsListPage } from "./features/BuildsList"
import { PullRequestBuildsPage } from "./features/PullRequestBuildsList"
import { SitesListView } from "./features/SitesListView"
import { SiteInformationView } from "./features/SiteInformationView"
import { OrganizationDetailsView } from "./features/OrganizationDetailsView"
import {
  SignupPage,
  LoginPage,
  UserSettings,
  SSOLoginPage,
} from "./features/UserAccount"
import { CreateOrganization } from "./features/CreateOrganization"
import { AcceptInvitation } from "./features/AcceptInvitation"
import {
  Maintenance,
  NotFound,
  OrganizationIdle,
  OrganizationOverage,
} from "./features/ServiceRoutes"
import { SitePreviewAuth } from "./features/SitePreview"
import { BuildLogsPage, BuildDetailsPage } from "./features/BuildDetails"
import { BuildFunctionPage } from "./features/Functions"

function Dashboard() {
  const { flags } = useFlags()
  return (
    <Router>
      <Maintenance path="/dashboard/maintenance" />
      <OrganizationOverage path="/dashboard/overage" />
      <OrganizationIdle path="/dashboard/idle" />
      <Redirect from="/dashboard/" to="/dashboard/login" noThrow />
      <SignupPage path="/dashboard/signup" />
      <LoginPage path="/dashboard/login" />
      <SSOLoginPage path="/dashboard/sso" />
      <AuthClose path="/dashboard/close" />
      <SitePreviewAuth path="/dashboard/previews/login" />
      <AuthRedirect path="/dashboard/redirect" />
      <ProtectedRoute path="/dashboard/welcome" component={LeadCapture} />

      {flags.protectedOrganizationRoutes ? (
        <ProtectedOrganizationRoute
          path="/dashboard/welcome/upgrade/:organizationId"
          component={TrialPlanUpsell}
        />
      ) : (
        <ProtectedRoute
          path="/dashboard/welcome/upgrade/:organizationId"
          component={TrialPlanUpsell}
        />
      )}
      <ProtectedRoute
        component={AcceptInvitation}
        path="/dashboard/invitations/:invitationId"
      />
      <Logout path="/dashboard/logout" />

      <ProtectedRoute
        component={BuildLogsPage}
        path="/dashboard/builds/:buildId/logs"
      />
      <ProtectedRoute component={DeployNowWizard} path="/dashboard/deploynow" />

      <ProtectedRoute
        path="/dashboard/organization/create"
        component={CreateOrganization}
      />

      {/* START Site creation paths */}

      {flags.protectedOrganizationRoutes ? (
        <ProtectedOrganizationRoute
          component={SiteCreationTypePicker}
          path="/dashboard/organization/:organizationId/sites/create"
        />
      ) : (
        <ProtectedRoute
          component={SiteCreationTypePicker}
          path="/dashboard/organization/:organizationId/sites/create"
        />
      )}
      {/* Import from repo */}
      {flags.protectedOrganizationRoutes ? (
        <ProtectedOrganizationRoute
          component={ImportSiteWizard}
          path="/dashboard/organization/:organizationId/sites/import/sourceProvider"
        />
      ) : (
        <ProtectedRoute
          component={ImportSiteWizard}
          path="/dashboard/organization/:organizationId/sites/import/sourceProvider"
        />
      )}

      {flags.protectedOrganizationRoutes ? (
        <ProtectedOrganizationRoute
          component={ImportSiteWizard}
          path="/dashboard/organization/:organizationId/sites/import/repository"
        />
      ) : (
        <ProtectedRoute
          component={ImportSiteWizard}
          path="/dashboard/organization/:organizationId/sites/import/repository"
        />
      )}

      {flags.protectedOrganizationRoutes ? (
        <ProtectedOrganizationRoute
          component={ImportSiteWizard}
          path="/dashboard/organization/:organizationId/sites/import/:siteId/integrations"
        />
      ) : (
        <ProtectedRoute
          component={ImportSiteWizard}
          path="/dashboard/organization/:organizationId/sites/import/:siteId/integrations"
        />
      )}

      {flags.protectedOrganizationRoutes ? (
        <ProtectedOrganizationRoute
          component={ImportSiteWizard}
          path="/dashboard/organization/:organizationId/sites/import/:siteId/setup"
        />
      ) : (
        <ProtectedRoute
          component={ImportSiteWizard}
          path="/dashboard/organization/:organizationId/sites/import/:siteId/setup"
        />
      )}

      {flags.protectedOrganizationRoutes ? (
        <ProtectedOrganizationRoute
          component={ImportSiteWizard}
          path="/dashboard/organization/:organizationId/sites/import/:siteId"
        />
      ) : (
        <ProtectedRoute
          component={ImportSiteWizard}
          path="/dashboard/organization/:organizationId/sites/import/:siteId"
        />
      )}
      {/* Provision from a template */}
      {flags.protectedOrganizationRoutes ? (
        <ProtectedOrganizationRoute
          component={ProvisionSiteWizard}
          path="/dashboard/organization/:organizationId/sites/provision/organization"
        />
      ) : (
        <ProtectedRoute
          component={ProvisionSiteWizard}
          path="/dashboard/organization/:organizationId/sites/provision/organization"
        />
      )}

      {flags.protectedOrganizationRoutes ? (
        <ProtectedOrganizationRoute
          component={ProvisionSiteWizard}
          path="/dashboard/organization/:organizationId/sites/provision/cms"
        />
      ) : (
        <ProtectedRoute
          component={ProvisionSiteWizard}
          path="/dashboard/organization/:organizationId/sites/provision/cms"
        />
      )}

      {flags.protectedOrganizationRoutes ? (
        <ProtectedOrganizationRoute
          component={ProvisionSiteWizard}
          path="/dashboard/organization/:organizationId/sites/provision/:siteId/connect"
        />
      ) : (
        <ProtectedRoute
          component={ProvisionSiteWizard}
          path="/dashboard/organization/:organizationId/sites/provision/:siteId/connect"
        />
      )}

      {flags.protectedOrganizationRoutes ? (
        <ProtectedOrganizationRoute
          component={ProvisionSiteWizard}
          path="/dashboard/organization/:organizationId/sites/provision/:siteId/summary"
        />
      ) : (
        <ProtectedRoute
          component={ProvisionSiteWizard}
          path="/dashboard/organization/:organizationId/sites/provision/:siteId/summary"
        />
      )}

      {/* END Site creation paths */}
      {/* Quick site from a url*/}
      {flags.protectedOrganizationRoutes ? (
        <ProtectedOrganizationRoute
          component={DeployNowWizard}
          path="/dashboard/organization/:organizationId/sites/deploynow/:siteId/integrations"
        />
      ) : (
        <ProtectedRoute
          component={DeployNowWizard}
          path="/dashboard/organization/:organizationId/sites/deploynow/:siteId/integrations"
        />
      )}

      {flags.protectedOrganizationRoutes ? (
        <ProtectedOrganizationRoute
          component={DeployNowWizard}
          path="/dashboard/organization/:organizationId/sites/deploynow/:siteId/setup"
        />
      ) : (
        <ProtectedRoute
          component={DeployNowWizard}
          path="/dashboard/organization/:organizationId/sites/deploynow/:siteId/setup"
        />
      )}

      {/* END Site creation paths */}
      {flags.protectedOrganizationRoutes ? (
        <ProtectedOrganizationRoute
          component={BuildsListPage}
          path="/dashboard/:organizationId/sites/:siteId/branches/:branch/builds"
        />
      ) : (
        <ProtectedRoute
          component={BuildsListPage}
          path="/dashboard/:organizationId/sites/:siteId/branches/:branch/builds"
        />
      )}

      {flags.protectedOrganizationRoutes ? (
        <ProtectedOrganizationRoute
          component={PullRequestBuildsPage}
          path="/dashboard/:organizationId/sites/:siteId/pull-requests/:pullRequestId/builds"
        />
      ) : (
        <ProtectedRoute
          component={PullRequestBuildsPage}
          path="/dashboard/:organizationId/sites/:siteId/pull-requests/:pullRequestId/builds"
        />
      )}

      {flags.protectedOrganizationRoutes ? (
        <ProtectedOrganizationRoute
          component={BuildDetailsPage}
          path="/dashboard/:organizationId/sites/:siteId/builds/:buildId/details"
        />
      ) : (
        <ProtectedRoute
          component={BuildDetailsPage}
          path="/dashboard/:organizationId/sites/:siteId/builds/:buildId/details"
        />
      )}

      {flags.protectedOrganizationRoutes ? (
        <ProtectedOrganizationRoute
          component={BuildFunctionPage}
          path="/dashboard/:organizationId/sites/:siteId/builds/:buildId/functions/:functionId/details"
        />
      ) : (
        <ProtectedRoute
          component={BuildFunctionPage}
          path="/dashboard/:organizationId/sites/:siteId/builds/:buildId/functions/:functionId/details"
        />
      )}

      <ProtectedRoute component={SitesListView} path="/dashboard/sites" />
      <ProtectedRoute component={UserSettings} path="/dashboard/account" />

      {flags.protectedOrganizationRoutes ? (
        <ProtectedOrganizationRoute
          component={SiteInformationView}
          path="/dashboard/:organizationId/sites/:siteId/*"
        />
      ) : (
        <ProtectedRoute
          component={SiteInformationView}
          path="/dashboard/:organizationId/sites/:siteId/*"
        />
      )}

      {flags.protectedOrganizationRoutes ? (
        <ProtectedOrganizationRoute
          component={({ siteId, organizationId }) => (
            <Redirect
              to={`/dashboard/${organizationId}/sites/${siteId}/previews`}
              noThrow
            />
          )}
          path="/dashboard/:organizationId/sites/previews/:siteId"
        />
      ) : (
        <ProtectedRoute
          component={({ siteId, organizationId }) => (
            <Redirect
              to={`/dashboard/${organizationId}/sites/${siteId}/previews`}
              noThrow
            />
          )}
          path="/dashboard/:organizationId/sites/previews/:siteId"
        />
      )}

      {flags.protectedOrganizationRoutes ? (
        <ProtectedOrganizationRoute
          component={Billing}
          path="/dashboard/pricing/:organizationId"
        />
      ) : (
        <ProtectedRoute
          component={Billing}
          path="/dashboard/pricing/:organizationId"
        />
      )}

      {flags.protectedOrganizationRoutes ? (
        <ProtectedOrganizationRoute
          component={OrganizationDetailsView}
          path="/dashboard/organization/:organizationId/*"
        />
      ) : (
        <ProtectedRoute
          component={OrganizationDetailsView}
          path="/dashboard/organization/:organizationId/*"
        />
      )}

      <NotFound path="/dashboard/*" />
    </Router>
  )
}

export default Dashboard
