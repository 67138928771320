import { AvailablePlanNames } from "@modules/enums/constants"
import {
  BillingInterval,
  SubscriptionPlan,
  MachinePricingTier,
} from "@modules/graphql/types"
import {
  useAvailablePlansQuery,
  useAllAvailablePlansQuery,
} from "@modules/billing/queries.generated"
import { normalizePlan } from "../shared/utils"
import { useFlags } from "@modules/featureFlags"
import { NextPlanInfo, PlanTier } from "../components/RequestForm/types"

export const FREE_PLAN_ID = `free`

export const FREE_PLAN: SubscriptionPlan = {
  name: `Free`,
  features: [
    `Builds & Preview`,
    `Total of 25 real-time edits/day`,
    `Online documentation`,
    `Build a single branch`,
    `Fast builds `,
  ],
  formattedAmount: `$0.00`,
  formattedMonthlyAmount: `$0.00`,
  id: FREE_PLAN_ID,
  planId: FREE_PLAN_ID,
  amount: 0,
  isRecommended: false,
  buildsTier: MachinePricingTier.Free,
  hostingTier: MachinePricingTier.Free,
}

export const useFilteredPlans = ({
  organizationId,
  interval,
  planName: currentPlanName,
  skip,
}: {
  organizationId?: string
  interval: BillingInterval
  planName?: string
  skip?: boolean
}) => {
  const { flags } = useFlags()
  const planGroup = flags.multiTiersPlans
    ? `GATSBY_BUILDS_HOSTING_SELF_SERVE`
    : `GATSBY_INDIVIDUAL_TEAM_PRICING_GROUP`

  const { loading, error, data } = useAvailablePlansQuery({
    variables: {
      organizationId,
      interval: interval,
      group: planGroup,
    },
    skip,
  })

  const availableSubscriptionPlans =
    (data && data.availableSubscriptionPlans) || []

  const filteredPlans = currentPlanName
    ? availableSubscriptionPlans.filter(plan => plan.name !== currentPlanName)
    : availableSubscriptionPlans

  const withFreePlan =
    filteredPlans.length > 0 &&
    (!currentPlanName || AvailablePlanNames.includes(currentPlanName))

  const finalPlans = withFreePlan
    ? [...filteredPlans, FREE_PLAN]
    : filteredPlans

  return {
    plans: finalPlans.map(normalizePlan),
    loading,
    error,
  }
}

export const useAllPlans = ({
  organizationId,
  skip,
}: {
  organizationId?: string
  skip?: boolean
}) => {
  const { loading, error, data } = useAllAvailablePlansQuery({
    variables: {
      organizationId,
      group: `GATSBY_BUILDS_HOSTING_SELF_SERVE`,
    },
    skip,
  })

  const monthlyPlans = (data && data.monthlyPlans) || []
  const annualPlans = (data && data.annualPlans) || []
  const allPlans = [...monthlyPlans, ...annualPlans]
  const finalPlans = allPlans.length > 0 ? [...allPlans, FREE_PLAN] : allPlans

  return {
    plans: finalPlans,
    loading,
    error,
  }
}

export const isBillingChange = (
  nextPlanInfo: NextPlanInfo,
  billing: {
    plan: {
      hostingTier: PlanTier
      buildsTier: PlanTier
      interval: `MONTHLY` | `ANNUAL`
    }
  }
): boolean => {
  const buildsTierChange = nextPlanInfo.buildsTier !== billing.plan.buildsTier
  const hostingTierChange =
    nextPlanInfo.hostingTier !== billing.plan.hostingTier
  const billingIntervalChange =
    nextPlanInfo.billingInterval !== billing.plan.interval

  if (buildsTierChange || hostingTierChange) {
    return true
  } else if (billingIntervalChange) {
    const plans = new Set(["FREE", "ENTERPRISE"]) as Set<PlanTier>
    return !plans.has(billing.plan.buildsTier)
  } else {
    return false
  }
}
